:root, :host {
  --ids-section-display: flex;
  --ids-section-width: 100%;
  --ids-section-flex-direction: column;
  --ids-section-padding-sm: var(--system-layout-gap-sm) 0;
  --ids-section-padding-md: var(--system-layout-gap-md) 0;
  --ids-section-padding-lg: var(--system-layout-gap-lg) 0;
  --ids-section-gap-sm: var(--system-layout-gap-sm);
  --ids-section-gap-md: var(--system-layout-gap-md);
  --ids-section-gap-lg: var(--system-layout-gap-lg);
  --ids-section-content-display: flex;
  --ids-section-content-width: 100%;
  --ids-section-content-flex-direction: column;
  --ids-section-content-padding-sm: 0 var(--system-layout-gap-sm);
  --ids-section-content-padding-md: 0 var(--system-layout-gap-md);
  --ids-section-content-padding-lg: 0;
  --ids-section-content-max-width-sm: unset;
  --ids-section-content-max-width-md: calc(72.5rem + 2 * var(--ids-section-content-padding-md));
  --ids-section-content-max-width-lg: 72.5rem;
  --ids-section-content-margin: 0 auto;
  --ids-section-content-gap-sm: var(--system-layout-gap-sm);
  --ids-section-content-gap-md: var(--system-layout-gap-md);
  --ids-section-content-gap-lg: var(--system-layout-gap-lg);
  --ids-grid-display: flex;
  --ids-grid-width: 100%;
  --ids-grid-flex-wrap: wrap;
  --ids-grid-gap-sm: var(--system-layout-gap-sm);
  --ids-grid-gap-md: var(--system-layout-gap-md);
  --ids-grid-gap-lg: var(--system-layout-gap-lg);
}

.if.section {
  display: var(--ids-section-display);
  width: var(--ids-section-width);
  flex-direction: var(--ids-section-flex-direction);
  padding: var(--ids-section-padding-sm);
  gap: var(--ids-section-gap-sm);
}

.if.section-content {
  display: var(--ids-section-content-display);
  width: var(--ids-section-content-width);
  max-width: var(--ids-section-content-max-width-sm);
  flex-direction: var(--ids-section-content-flex-direction);
  padding: var(--ids-section-content-padding-sm);
  margin: var(--ids-section-content-margin);
  gap: var(--ids-section-content-gap-sm);
}

.if.grid {
  display: var(--ids-grid-display);
  width: var(--ids-grid-width);
  flex-wrap: var(--ids-grid-flex-wrap);
  gap: var(--ids-grid-gap-sm);
}

.if.grid .if.col-1 {
  max-width: calc((100% - 3 * var(--ids-grid-gap-sm)) / 4 * 1 + 0 * var(--ids-grid-gap-sm));
  flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-sm)) / 4 * 1 + 0 * var(--ids-grid-gap-sm));
}

.if.grid .if.col-2 {
  max-width: calc((100% - 3 * var(--ids-grid-gap-sm)) / 4 * 2 + 1 * var(--ids-grid-gap-sm));
  flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-sm)) / 4 * 2 + 1 * var(--ids-grid-gap-sm));
}

.if.grid .if.col-3 {
  max-width: calc((100% - 3 * var(--ids-grid-gap-sm)) / 4 * 3 + 2 * var(--ids-grid-gap-sm));
  flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-sm)) / 4 * 3 + 2 * var(--ids-grid-gap-sm));
}

.if.grid .if.col-4 {
  max-width: calc((100% - 3 * var(--ids-grid-gap-sm)) / 4 * 4 + 3 * var(--ids-grid-gap-sm));
  flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-sm)) / 4 * 4 + 3 * var(--ids-grid-gap-sm));
}

.if.grid .if.col-fit {
  width: auto;
  max-width: none;
  flex: 0 0 auto;
}

.if.grid .if.col-grow {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}

.if.grid.no-gap {
  gap: 0;
}

.if.grid.no-gap > .if.col-1 {
  max-width: calc(100% * (1) / 4);
  flex: 0 0 calc(100% * (1) / 4);
}

.if.grid.no-gap > .if.col-2 {
  max-width: calc(100% * (2) / 4);
  flex: 0 0 calc(100% * (2) / 4);
}

.if.grid.no-gap > .if.col-3 {
  max-width: calc(100% * (3) / 4);
  flex: 0 0 calc(100% * (3) / 4);
}

.if.grid.no-gap > .if.col-4 {
  max-width: calc(100% * (4) / 4);
  flex: 0 0 calc(100% * (4) / 4);
}

.if.grid.no-gap .if.col-fit {
  width: auto;
  max-width: none;
  flex: 0 0 auto;
}

.if.grid.no-gap .if.col-grow {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}

@media (min-width: 43.75rem) {
  .if.section {
    padding: var(--ids-section-padding-md);
    gap: var(--ids-section-gap-md);
  }
  .if.section-content {
    max-width: var(--ids-section-content-max-width-md);
    padding: var(--ids-section-content-padding-md);
    gap: var(--ids-section-content-gap-md);
  }
  .if.grid {
    gap: var(--ids-grid-gap-md);
  }
  .if.grid .if.col-1 {
    max-width: calc((100% - 3 * var(--ids-grid-gap-md)) / 4 * 1 + 0 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-md)) / 4 * 1 + 0 * var(--ids-grid-gap-md));
  }
  .if.grid .if.col-2 {
    max-width: calc((100% - 3 * var(--ids-grid-gap-md)) / 4 * 2 + 1 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-md)) / 4 * 2 + 1 * var(--ids-grid-gap-md));
  }
  .if.grid .if.col-3 {
    max-width: calc((100% - 3 * var(--ids-grid-gap-md)) / 4 * 3 + 2 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-md)) / 4 * 3 + 2 * var(--ids-grid-gap-md));
  }
  .if.grid .if.col-4 {
    max-width: calc((100% - 3 * var(--ids-grid-gap-md)) / 4 * 4 + 3 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-md)) / 4 * 4 + 3 * var(--ids-grid-gap-md));
  }
  .if.grid .if.col-fit {
    width: auto;
    max-width: none;
    flex: 0 0 auto;
  }
  .if.grid .if.col-grow {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
  .if.grid .if.md-col-1 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 1 + 0 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 1 + 0 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-2 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 2 + 1 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 2 + 1 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-3 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 3 + 2 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 3 + 2 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-4 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 4 + 3 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 4 + 3 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-5 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 5 + 4 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 5 + 4 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-6 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 6 + 5 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 6 + 5 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-7 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 7 + 6 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 7 + 6 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-8 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 8 + 7 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 8 + 7 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-9 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 9 + 8 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 9 + 8 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-10 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 10 + 9 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 10 + 9 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-11 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 11 + 10 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 11 + 10 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-12 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 12 + 11 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 12 + 11 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-fit {
    width: auto;
    max-width: none;
    flex: 0 0 auto;
  }
  .if.grid .if.md-col-grow {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
  .if.grid.no-gap > .if.md-col-1 {
    max-width: calc(100% * (1) / 12);
    flex: 0 0 calc(100% * (1) / 12);
  }
  .if.grid.no-gap > .if.md-col-2 {
    max-width: calc(100% * (2) / 12);
    flex: 0 0 calc(100% * (2) / 12);
  }
  .if.grid.no-gap > .if.md-col-3 {
    max-width: calc(100% * (3) / 12);
    flex: 0 0 calc(100% * (3) / 12);
  }
  .if.grid.no-gap > .if.md-col-4 {
    max-width: calc(100% * (4) / 12);
    flex: 0 0 calc(100% * (4) / 12);
  }
  .if.grid.no-gap > .if.md-col-5 {
    max-width: calc(100% * (5) / 12);
    flex: 0 0 calc(100% * (5) / 12);
  }
  .if.grid.no-gap > .if.md-col-6 {
    max-width: calc(100% * (6) / 12);
    flex: 0 0 calc(100% * (6) / 12);
  }
  .if.grid.no-gap > .if.md-col-7 {
    max-width: calc(100% * (7) / 12);
    flex: 0 0 calc(100% * (7) / 12);
  }
  .if.grid.no-gap > .if.md-col-8 {
    max-width: calc(100% * (8) / 12);
    flex: 0 0 calc(100% * (8) / 12);
  }
  .if.grid.no-gap > .if.md-col-9 {
    max-width: calc(100% * (9) / 12);
    flex: 0 0 calc(100% * (9) / 12);
  }
  .if.grid.no-gap > .if.md-col-10 {
    max-width: calc(100% * (10) / 12);
    flex: 0 0 calc(100% * (10) / 12);
  }
  .if.grid.no-gap > .if.md-col-11 {
    max-width: calc(100% * (11) / 12);
    flex: 0 0 calc(100% * (11) / 12);
  }
  .if.grid.no-gap > .if.md-col-12 {
    max-width: calc(100% * (12) / 12);
    flex: 0 0 calc(100% * (12) / 12);
  }
  .if.grid.no-gap .if.md-col-fit {
    width: auto;
    max-width: none;
    flex: 0 0 auto;
  }
  .if.grid.no-gap .if.md-col-grow {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (min-width: 77.5rem) {
  .if.section {
    padding: var(--ids-section-padding-lg);
    gap: var(--ids-section-gap-lg);
  }
  .if.section-content {
    max-width: var(--ids-section-content-max-width-lg);
    padding: var(--ids-section-content-padding-lg);
    gap: var(--ids-section-content-gap-lg);
  }
  .if.grid {
    gap: var(--ids-grid-gap-lg);
  }
  .if.grid .if.col-1 {
    max-width: calc((100% - 3 * var(--ids-grid-gap-lg)) / 4 * 1 + 0 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-lg)) / 4 * 1 + 0 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.col-2 {
    max-width: calc((100% - 3 * var(--ids-grid-gap-lg)) / 4 * 2 + 1 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-lg)) / 4 * 2 + 1 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.col-3 {
    max-width: calc((100% - 3 * var(--ids-grid-gap-lg)) / 4 * 3 + 2 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-lg)) / 4 * 3 + 2 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.col-4 {
    max-width: calc((100% - 3 * var(--ids-grid-gap-lg)) / 4 * 4 + 3 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 3 * var(--ids-grid-gap-lg)) / 4 * 4 + 3 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.col-fit {
    width: auto;
    max-width: none;
    flex: 0 0 auto;
  }
  .if.grid .if.col-grow {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
  .if.grid .if.md-col-1 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 1 + 0 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 1 + 0 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-2 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 2 + 1 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 2 + 1 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-3 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 3 + 2 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 3 + 2 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-4 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 4 + 3 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 4 + 3 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-5 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 5 + 4 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 5 + 4 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-6 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 6 + 5 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 6 + 5 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-7 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 7 + 6 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 7 + 6 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-8 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 8 + 7 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 8 + 7 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-9 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 9 + 8 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 9 + 8 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-10 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 10 + 9 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 10 + 9 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-11 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 11 + 10 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 11 + 10 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-12 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 12 + 11 * var(--ids-grid-gap-md));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-md)) / 12 * 12 + 11 * var(--ids-grid-gap-md));
  }
  .if.grid .if.md-col-fit {
    width: auto;
    max-width: none;
    flex: 0 0 auto;
  }
  .if.grid .if.md-col-grow {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
  .if.grid .if.lg-col-1 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 1 + 0 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 1 + 0 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-2 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 2 + 1 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 2 + 1 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-3 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 3 + 2 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 3 + 2 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-4 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 4 + 3 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 4 + 3 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-5 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 5 + 4 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 5 + 4 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-6 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 6 + 5 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 6 + 5 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-7 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 7 + 6 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 7 + 6 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-8 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 8 + 7 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 8 + 7 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-9 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 9 + 8 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 9 + 8 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-10 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 10 + 9 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 10 + 9 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-11 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 11 + 10 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 11 + 10 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-12 {
    max-width: calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 12 + 11 * var(--ids-grid-gap-lg));
    flex: 0 0 calc((100% - 11 * var(--ids-grid-gap-lg)) / 12 * 12 + 11 * var(--ids-grid-gap-lg));
  }
  .if.grid .if.lg-col-fit {
    width: auto;
    max-width: none;
    flex: 0 0 auto;
  }
  .if.grid .if.lg-col-grow {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
  .if.grid.no-gap > .if.lg-col-1 {
    max-width: calc(100% * (1) / 12);
    flex: 0 0 calc(100% * (1) / 12);
  }
  .if.grid.no-gap > .if.lg-col-2 {
    max-width: calc(100% * (2) / 12);
    flex: 0 0 calc(100% * (2) / 12);
  }
  .if.grid.no-gap > .if.lg-col-3 {
    max-width: calc(100% * (3) / 12);
    flex: 0 0 calc(100% * (3) / 12);
  }
  .if.grid.no-gap > .if.lg-col-4 {
    max-width: calc(100% * (4) / 12);
    flex: 0 0 calc(100% * (4) / 12);
  }
  .if.grid.no-gap > .if.lg-col-5 {
    max-width: calc(100% * (5) / 12);
    flex: 0 0 calc(100% * (5) / 12);
  }
  .if.grid.no-gap > .if.lg-col-6 {
    max-width: calc(100% * (6) / 12);
    flex: 0 0 calc(100% * (6) / 12);
  }
  .if.grid.no-gap > .if.lg-col-7 {
    max-width: calc(100% * (7) / 12);
    flex: 0 0 calc(100% * (7) / 12);
  }
  .if.grid.no-gap > .if.lg-col-8 {
    max-width: calc(100% * (8) / 12);
    flex: 0 0 calc(100% * (8) / 12);
  }
  .if.grid.no-gap > .if.lg-col-9 {
    max-width: calc(100% * (9) / 12);
    flex: 0 0 calc(100% * (9) / 12);
  }
  .if.grid.no-gap > .if.lg-col-10 {
    max-width: calc(100% * (10) / 12);
    flex: 0 0 calc(100% * (10) / 12);
  }
  .if.grid.no-gap > .if.lg-col-11 {
    max-width: calc(100% * (11) / 12);
    flex: 0 0 calc(100% * (11) / 12);
  }
  .if.grid.no-gap > .if.lg-col-12 {
    max-width: calc(100% * (12) / 12);
    flex: 0 0 calc(100% * (12) / 12);
  }
  .if.grid.no-gap .if.lg-col-fit {
    width: auto;
    max-width: none;
    flex: 0 0 auto;
  }
  .if.grid.no-gap .if.lg-col-grow {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }
}
.if.grid-container {
  display: grid;
}

.if.flex {
  display: flex;
}

.if.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.if.flex-row {
  display: flex;
  flex-direction: row;
}

.if.flex-column {
  display: flex;
  flex-direction: column;
}

.if.flex-wrap {
  flex-wrap: wrap;
}

.if.flex-no-wrap {
  flex-wrap: nowrap;
}

.if.flex-grow {
  flex-grow: 1;
}

.if.flex-grow-0 {
  flex-grow: 0;
}

.if.flex-shrink {
  flex-shrink: 1;
}

.if.flex-shrink-0 {
  flex-shrink: 0;
}

.if.position-relative {
  position: relative;
}

.if.position-absolute {
  position: absolute;
}

.if.display-none {
  display: none;
}

.if.display-block {
  display: block;
}

.if.overflow-hidden {
  overflow: hidden;
}

.if.overflow-scroll {
  overflow: scroll;
}

.if.overflow-y-scroll {
  overflow-y: scroll;
}

.if.overflow-x-scroll {
  overflow-x: scroll;
}

.if.overflow-auto {
  overflow: auto;
}

.if.overflow-y-auto {
  overflow-y: auto;
}

.if.overflow-x-auto {
  overflow-x: auto;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content */
.if.justify-content-center {
  justify-content: center;
}

.if.justify-content-start {
  justify-content: start;
}

.if.justify-content-end {
  justify-content: end;
}

.if.justify-content-left {
  justify-content: left;
}

.if.justify-content-right {
  justify-content: right;
}

.if.justify-content-space-between {
  justify-content: space-between;
}

.if.justify-content-space-around {
  justify-content: space-around;
}

.if.justify-content-space-evenly {
  justify-content: space-evenly;
}

.if.justify-content-stretch {
  justify-content: stretch;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-items */
.if.justify-items-normal {
  justify-items: normal;
}

.if.justify-items-stretch {
  justify-items: stretch;
}

.if.justify-items-center {
  justify-items: center;
}

.if.justify-items-start {
  justify-items: start;
}

.if.justify-items-end {
  justify-items: end;
}

.if.justify-items-left {
  justify-items: left;
}

.if.justify-items-right {
  justify-items: right;
}

.if.justify-items-baseline {
  justify-items: baseline;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-self */
.if.justify-self-auto {
  justify-self: auto;
}

.if.justify-self-normal {
  justify-self: normal;
}

.if.justify-self-stretch {
  justify-self: stretch;
}

.if.justify-self-center {
  justify-self: center;
}

.if.justify-self-start {
  justify-self: start;
}

.if.justify-self-end {
  justify-self: end;
}

.if.justify-self-left {
  justify-self: left;
}

.if.justify-self-right {
  justify-self: right;
}

.if.justify-self-baseline {
  justify-self: baseline;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/align-content */
.if.align-content-center {
  align-content: center;
}

.if.align-content-start {
  align-content: start;
}

.if.align-content-end {
  align-content: end;
}

.if.align-content-baseline {
  align-content: baseline;
}

.if.align-content-space-between {
  align-content: space-between;
}

.if.align-content-space-around {
  align-content: space-around;
}

.if.align-content-space-evenly {
  align-content: space-evenly;
}

.if.align-content-stretch {
  align-content: stretch;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/align-items */
.if.align-items-center {
  align-items: center;
}

.if.align-items-start {
  align-items: start;
}

.if.align-items-end {
  align-items: end;
}

.if.align-items-baseline {
  align-items: baseline;
}

.if.align-items-stretch {
  align-items: stretch;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/align-self */
.if.align-self-auto {
  align-self: auto;
}

.if.align-self-center {
  align-self: center;
}

.if.align-self-start {
  align-self: start;
}

.if.align-self-end {
  align-self: end;
}

.if.align-self-baseline {
  align-self: baseline;
}

.if.align-self-stretch {
  align-self: stretch;
}

.if.m-2 {
  margin: 0.125rem;
}

.if.my-2 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.if.mx-2 {
  margin-right: 0.125rem;
  margin-left: 0.125rem;
}

.if.m-4 {
  margin: 0.25rem;
}

.if.my-4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.if.mx-4 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.if.m-8 {
  margin: 0.5rem;
}

.if.my-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.if.mx-8 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.if.m-12 {
  margin: 0.75rem;
}

.if.my-12 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.if.mx-12 {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.if.m-16 {
  margin: 1rem;
}

.if.my-16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.if.mx-16 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.if.m-20 {
  margin: 1.25rem;
}

.if.my-20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.if.mx-20 {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.if.m-24 {
  margin: 1.5rem;
}

.if.my-24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.if.mx-24 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.if.m-32 {
  margin: 2rem;
}

.if.my-32 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.if.mx-32 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.if.m-40 {
  margin: 2.5rem;
}

.if.my-40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.if.mx-40 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.if.m-48 {
  margin: 3rem;
}

.if.my-48 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.if.mx-48 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.if.m-64 {
  margin: 4rem;
}

.if.my-64 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.if.mx-64 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.if.m-88 {
  margin: 5.5rem;
}

.if.my-88 {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}

.if.mx-88 {
  margin-right: 5.5rem;
  margin-left: 5.5rem;
}

.if.m-120 {
  margin: 7.5rem;
}

.if.my-120 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.if.mx-120 {
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}

.if.p-2 {
  padding: 0.125rem;
}

.if.py-2 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.if.px-2 {
  padding-right: 0.125rem;
  padding-left: 0.125rem;
}

.if.p-4 {
  padding: 0.25rem;
}

.if.py-4 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.if.px-4 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.if.p-8 {
  padding: 0.5rem;
}

.if.py-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.if.px-8 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.if.p-12 {
  padding: 0.75rem;
}

.if.py-12 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.if.px-12 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.if.p-16 {
  padding: 1rem;
}

.if.py-16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.if.px-16 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.if.p-20 {
  padding: 1.25rem;
}

.if.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.if.px-20 {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.if.p-24 {
  padding: 1.5rem;
}

.if.py-24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.if.px-24 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.if.p-32 {
  padding: 2rem;
}

.if.py-32 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.if.px-32 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.if.p-40 {
  padding: 2.5rem;
}

.if.py-40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.if.px-40 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.if.p-48 {
  padding: 3rem;
}

.if.py-48 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.if.px-48 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.if.p-64 {
  padding: 4rem;
}

.if.py-64 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.if.px-64 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.if.p-88 {
  padding: 5.5rem;
}

.if.py-88 {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}

.if.px-88 {
  padding-right: 5.5rem;
  padding-left: 5.5rem;
}

.if.p-120 {
  padding: 7.5rem;
}

.if.py-120 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.if.px-120 {
  padding-right: 7.5rem;
  padding-left: 7.5rem;
}

.if.mt-2 {
  margin-top: 0.125rem;
}

.if.mb-2 {
  margin-bottom: 0.125rem;
}

.if.mr-2 {
  margin-right: 0.125rem;
}

.if.ml-2 {
  margin-left: 0.125rem;
}

.if.mt-4 {
  margin-top: 0.25rem;
}

.if.mb-4 {
  margin-bottom: 0.25rem;
}

.if.mr-4 {
  margin-right: 0.25rem;
}

.if.ml-4 {
  margin-left: 0.25rem;
}

.if.mt-8 {
  margin-top: 0.5rem;
}

.if.mb-8 {
  margin-bottom: 0.5rem;
}

.if.mr-8 {
  margin-right: 0.5rem;
}

.if.ml-8 {
  margin-left: 0.5rem;
}

.if.mt-12 {
  margin-top: 0.75rem;
}

.if.mb-12 {
  margin-bottom: 0.75rem;
}

.if.mr-12 {
  margin-right: 0.75rem;
}

.if.ml-12 {
  margin-left: 0.75rem;
}

.if.mt-16 {
  margin-top: 1rem;
}

.if.mb-16 {
  margin-bottom: 1rem;
}

.if.mr-16 {
  margin-right: 1rem;
}

.if.ml-16 {
  margin-left: 1rem;
}

.if.mt-20 {
  margin-top: 1.25rem;
}

.if.mb-20 {
  margin-bottom: 1.25rem;
}

.if.mr-20 {
  margin-right: 1.25rem;
}

.if.ml-20 {
  margin-left: 1.25rem;
}

.if.mt-24 {
  margin-top: 1.5rem;
}

.if.mb-24 {
  margin-bottom: 1.5rem;
}

.if.mr-24 {
  margin-right: 1.5rem;
}

.if.ml-24 {
  margin-left: 1.5rem;
}

.if.mt-32 {
  margin-top: 2rem;
}

.if.mb-32 {
  margin-bottom: 2rem;
}

.if.mr-32 {
  margin-right: 2rem;
}

.if.ml-32 {
  margin-left: 2rem;
}

.if.mt-40 {
  margin-top: 2.5rem;
}

.if.mb-40 {
  margin-bottom: 2.5rem;
}

.if.mr-40 {
  margin-right: 2.5rem;
}

.if.ml-40 {
  margin-left: 2.5rem;
}

.if.mt-48 {
  margin-top: 3rem;
}

.if.mb-48 {
  margin-bottom: 3rem;
}

.if.mr-48 {
  margin-right: 3rem;
}

.if.ml-48 {
  margin-left: 3rem;
}

.if.mt-64 {
  margin-top: 4rem;
}

.if.mb-64 {
  margin-bottom: 4rem;
}

.if.mr-64 {
  margin-right: 4rem;
}

.if.ml-64 {
  margin-left: 4rem;
}

.if.mt-88 {
  margin-top: 5.5rem;
}

.if.mb-88 {
  margin-bottom: 5.5rem;
}

.if.mr-88 {
  margin-right: 5.5rem;
}

.if.ml-88 {
  margin-left: 5.5rem;
}

.if.mt-120 {
  margin-top: 7.5rem;
}

.if.mb-120 {
  margin-bottom: 7.5rem;
}

.if.mr-120 {
  margin-right: 7.5rem;
}

.if.ml-120 {
  margin-left: 7.5rem;
}

.if.pt-2 {
  padding-top: 0.125rem;
}

.if.pb-2 {
  padding-bottom: 0.125rem;
}

.if.pr-2 {
  padding-right: 0.125rem;
}

.if.pl-2 {
  padding-left: 0.125rem;
}

.if.pt-4 {
  padding-top: 0.25rem;
}

.if.pb-4 {
  padding-bottom: 0.25rem;
}

.if.pr-4 {
  padding-right: 0.25rem;
}

.if.pl-4 {
  padding-left: 0.25rem;
}

.if.pt-8 {
  padding-top: 0.5rem;
}

.if.pb-8 {
  padding-bottom: 0.5rem;
}

.if.pr-8 {
  padding-right: 0.5rem;
}

.if.pl-8 {
  padding-left: 0.5rem;
}

.if.pt-12 {
  padding-top: 0.75rem;
}

.if.pb-12 {
  padding-bottom: 0.75rem;
}

.if.pr-12 {
  padding-right: 0.75rem;
}

.if.pl-12 {
  padding-left: 0.75rem;
}

.if.pt-16 {
  padding-top: 1rem;
}

.if.pb-16 {
  padding-bottom: 1rem;
}

.if.pr-16 {
  padding-right: 1rem;
}

.if.pl-16 {
  padding-left: 1rem;
}

.if.pt-20 {
  padding-top: 1.25rem;
}

.if.pb-20 {
  padding-bottom: 1.25rem;
}

.if.pr-20 {
  padding-right: 1.25rem;
}

.if.pl-20 {
  padding-left: 1.25rem;
}

.if.pt-24 {
  padding-top: 1.5rem;
}

.if.pb-24 {
  padding-bottom: 1.5rem;
}

.if.pr-24 {
  padding-right: 1.5rem;
}

.if.pl-24 {
  padding-left: 1.5rem;
}

.if.pt-32 {
  padding-top: 2rem;
}

.if.pb-32 {
  padding-bottom: 2rem;
}

.if.pr-32 {
  padding-right: 2rem;
}

.if.pl-32 {
  padding-left: 2rem;
}

.if.pt-40 {
  padding-top: 2.5rem;
}

.if.pb-40 {
  padding-bottom: 2.5rem;
}

.if.pr-40 {
  padding-right: 2.5rem;
}

.if.pl-40 {
  padding-left: 2.5rem;
}

.if.pt-48 {
  padding-top: 3rem;
}

.if.pb-48 {
  padding-bottom: 3rem;
}

.if.pr-48 {
  padding-right: 3rem;
}

.if.pl-48 {
  padding-left: 3rem;
}

.if.pt-64 {
  padding-top: 4rem;
}

.if.pb-64 {
  padding-bottom: 4rem;
}

.if.pr-64 {
  padding-right: 4rem;
}

.if.pl-64 {
  padding-left: 4rem;
}

.if.pt-88 {
  padding-top: 5.5rem;
}

.if.pb-88 {
  padding-bottom: 5.5rem;
}

.if.pr-88 {
  padding-right: 5.5rem;
}

.if.pl-88 {
  padding-left: 5.5rem;
}

.if.pt-120 {
  padding-top: 7.5rem;
}

.if.pb-120 {
  padding-bottom: 7.5rem;
}

.if.pr-120 {
  padding-right: 7.5rem;
}

.if.pl-120 {
  padding-left: 7.5rem;
}

.if.no-padding {
  padding: 0;
}

.if.no-margin {
  margin: 0;
}

.if.mx-auto,
.if.mh-auto {
  margin-right: auto;
  margin-left: auto;
}

.if.size-w-8 {
  width: 0.5rem;
}

.if.size-h-8 {
  height: 0.5rem;
}

.if.size-w-10 {
  width: 0.625rem;
}

.if.size-h-10 {
  height: 0.625rem;
}

.if.size-w-12 {
  width: 0.75rem;
}

.if.size-h-12 {
  height: 0.75rem;
}

.if.size-w-16 {
  width: 1rem;
}

.if.size-h-16 {
  height: 1rem;
}

.if.size-w-18 {
  width: 1.125rem;
}

.if.size-h-18 {
  height: 1.125rem;
}

.if.size-w-20 {
  width: 1.25rem;
}

.if.size-h-20 {
  height: 1.25rem;
}

.if.size-w-24 {
  width: 1.5rem;
}

.if.size-h-24 {
  height: 1.5rem;
}

.if.size-w-32 {
  width: 2rem;
}

.if.size-h-32 {
  height: 2rem;
}

.if.size-w-36 {
  width: 2.25rem;
}

.if.size-h-36 {
  height: 2.25rem;
}

.if.size-w-40 {
  width: 2.5rem;
}

.if.size-h-40 {
  height: 2.5rem;
}

.if.size-w-48 {
  width: 3rem;
}

.if.size-h-48 {
  height: 3rem;
}

.if.size-w-64 {
  width: 4rem;
}

.if.size-h-64 {
  height: 4rem;
}

.if.size-w-72 {
  width: 4.5rem;
}

.if.size-h-72 {
  height: 4.5rem;
}

.if.size-w-96 {
  width: 6rem;
}

.if.size-h-96 {
  height: 6rem;
}

.if.size-w-128 {
  width: 8rem;
}

.if.size-h-128 {
  height: 8rem;
}

.if.size-w-144 {
  width: 9rem;
}

.if.size-h-144 {
  height: 9rem;
}

.if.size-w-192 {
  width: 12rem;
}

.if.size-h-192 {
  height: 12rem;
}

.if.size-w-256 {
  width: 16rem;
}

.if.size-h-256 {
  height: 16rem;
}

.if.size-w-384 {
  width: 24rem;
}

.if.size-h-384 {
  height: 24rem;
}

.if.size-w-512 {
  width: 32rem;
}

.if.size-h-512 {
  height: 32rem;
}

.if.size-w-768 {
  width: 48rem;
}

.if.size-h-768 {
  height: 48rem;
}

.if.size-w-1024 {
  width: 64rem;
}

.if.size-h-1024 {
  height: 64rem;
}

.if.size-8 {
  width: 0.5rem;
  height: 0.5rem;
}

.if.size-10 {
  width: 0.625rem;
  height: 0.625rem;
}

.if.size-12 {
  width: 0.75rem;
  height: 0.75rem;
}

.if.size-16 {
  width: 1rem;
  height: 1rem;
}

.if.size-18 {
  width: 1.125rem;
  height: 1.125rem;
}

.if.size-20 {
  width: 1.25rem;
  height: 1.25rem;
}

.if.size-24 {
  width: 1.5rem;
  height: 1.5rem;
}

.if.size-32 {
  width: 2rem;
  height: 2rem;
}

.if.size-36 {
  width: 2.25rem;
  height: 2.25rem;
}

.if.size-40 {
  width: 2.5rem;
  height: 2.5rem;
}

.if.size-48 {
  width: 3rem;
  height: 3rem;
}

.if.size-64 {
  width: 4rem;
  height: 4rem;
}

.if.size-72 {
  width: 4.5rem;
  height: 4.5rem;
}

.if.size-96 {
  width: 6rem;
  height: 6rem;
}

.if.size-128 {
  width: 8rem;
  height: 8rem;
}

.if.size-144 {
  width: 9rem;
  height: 9rem;
}

.if.size-192 {
  width: 12rem;
  height: 12rem;
}

.if.size-256 {
  width: 16rem;
  height: 16rem;
}

.if.size-384 {
  width: 24rem;
  height: 24rem;
}

.if.size-512 {
  width: 32rem;
  height: 32rem;
}

.if.size-768 {
  width: 48rem;
  height: 48rem;
}

.if.size-1024 {
  width: 64rem;
  height: 64rem;
}

.if.size-w-25p {
  width: 25%;
}

.if.size-h-25p {
  height: 25%;
}

.if.size-w-33p {
  width: 33.33%;
}

.if.size-h-33p {
  height: 33.33%;
}

.if.size-w-50p {
  width: 50%;
}

.if.size-h-50p {
  height: 50%;
}

.if.size-w-66p {
  width: 66.67%;
}

.if.size-h-66p {
  height: 66.67%;
}

.if.size-w-75p {
  width: 75%;
}

.if.size-h-75p {
  height: 75%;
}

.if.size-w-100p {
  width: 100%;
}

.if.size-h-100p {
  height: 100%;
}

.if.size-25p {
  width: 25%;
  height: 25%;
}

.if.size-33p {
  width: 33.33%;
  height: 33.33%;
}

.if.size-50p {
  width: 50%;
  height: 50%;
}

.if.size-66p {
  width: 66.67%;
  height: 66.67%;
}

.if.size-75p {
  width: 75%;
  height: 75%;
}

.if.size-100p {
  width: 100%;
  height: 100%;
}

.if.max-w-8 {
  max-width: 0.5rem;
}

.if.min-w-8 {
  min-width: 0.5rem;
}

.if.max-h-8 {
  max-height: 0.5rem;
}

.if.min-h-8 {
  min-height: 0.5rem;
}

.if.max-w-10 {
  max-width: 0.625rem;
}

.if.min-w-10 {
  min-width: 0.625rem;
}

.if.max-h-10 {
  max-height: 0.625rem;
}

.if.min-h-10 {
  min-height: 0.625rem;
}

.if.max-w-12 {
  max-width: 0.75rem;
}

.if.min-w-12 {
  min-width: 0.75rem;
}

.if.max-h-12 {
  max-height: 0.75rem;
}

.if.min-h-12 {
  min-height: 0.75rem;
}

.if.max-w-16 {
  max-width: 1rem;
}

.if.min-w-16 {
  min-width: 1rem;
}

.if.max-h-16 {
  max-height: 1rem;
}

.if.min-h-16 {
  min-height: 1rem;
}

.if.max-w-18 {
  max-width: 1.125rem;
}

.if.min-w-18 {
  min-width: 1.125rem;
}

.if.max-h-18 {
  max-height: 1.125rem;
}

.if.min-h-18 {
  min-height: 1.125rem;
}

.if.max-w-20 {
  max-width: 1.25rem;
}

.if.min-w-20 {
  min-width: 1.25rem;
}

.if.max-h-20 {
  max-height: 1.25rem;
}

.if.min-h-20 {
  min-height: 1.25rem;
}

.if.max-w-24 {
  max-width: 1.5rem;
}

.if.min-w-24 {
  min-width: 1.5rem;
}

.if.max-h-24 {
  max-height: 1.5rem;
}

.if.min-h-24 {
  min-height: 1.5rem;
}

.if.max-w-32 {
  max-width: 2rem;
}

.if.min-w-32 {
  min-width: 2rem;
}

.if.max-h-32 {
  max-height: 2rem;
}

.if.min-h-32 {
  min-height: 2rem;
}

.if.max-w-36 {
  max-width: 2.25rem;
}

.if.min-w-36 {
  min-width: 2.25rem;
}

.if.max-h-36 {
  max-height: 2.25rem;
}

.if.min-h-36 {
  min-height: 2.25rem;
}

.if.max-w-40 {
  max-width: 2.5rem;
}

.if.min-w-40 {
  min-width: 2.5rem;
}

.if.max-h-40 {
  max-height: 2.5rem;
}

.if.min-h-40 {
  min-height: 2.5rem;
}

.if.max-w-48 {
  max-width: 3rem;
}

.if.min-w-48 {
  min-width: 3rem;
}

.if.max-h-48 {
  max-height: 3rem;
}

.if.min-h-48 {
  min-height: 3rem;
}

.if.max-w-64 {
  max-width: 4rem;
}

.if.min-w-64 {
  min-width: 4rem;
}

.if.max-h-64 {
  max-height: 4rem;
}

.if.min-h-64 {
  min-height: 4rem;
}

.if.max-w-72 {
  max-width: 4.5rem;
}

.if.min-w-72 {
  min-width: 4.5rem;
}

.if.max-h-72 {
  max-height: 4.5rem;
}

.if.min-h-72 {
  min-height: 4.5rem;
}

.if.max-w-96 {
  max-width: 6rem;
}

.if.min-w-96 {
  min-width: 6rem;
}

.if.max-h-96 {
  max-height: 6rem;
}

.if.min-h-96 {
  min-height: 6rem;
}

.if.max-w-128 {
  max-width: 8rem;
}

.if.min-w-128 {
  min-width: 8rem;
}

.if.max-h-128 {
  max-height: 8rem;
}

.if.min-h-128 {
  min-height: 8rem;
}

.if.max-w-144 {
  max-width: 9rem;
}

.if.min-w-144 {
  min-width: 9rem;
}

.if.max-h-144 {
  max-height: 9rem;
}

.if.min-h-144 {
  min-height: 9rem;
}

.if.max-w-192 {
  max-width: 12rem;
}

.if.min-w-192 {
  min-width: 12rem;
}

.if.max-h-192 {
  max-height: 12rem;
}

.if.min-h-192 {
  min-height: 12rem;
}

.if.max-w-256 {
  max-width: 16rem;
}

.if.min-w-256 {
  min-width: 16rem;
}

.if.max-h-256 {
  max-height: 16rem;
}

.if.min-h-256 {
  min-height: 16rem;
}

.if.max-w-384 {
  max-width: 24rem;
}

.if.min-w-384 {
  min-width: 24rem;
}

.if.max-h-384 {
  max-height: 24rem;
}

.if.min-h-384 {
  min-height: 24rem;
}

.if.max-w-512 {
  max-width: 32rem;
}

.if.min-w-512 {
  min-width: 32rem;
}

.if.max-h-512 {
  max-height: 32rem;
}

.if.min-h-512 {
  min-height: 32rem;
}

.if.max-w-768 {
  max-width: 48rem;
}

.if.min-w-768 {
  min-width: 48rem;
}

.if.max-h-768 {
  max-height: 48rem;
}

.if.min-h-768 {
  min-height: 48rem;
}

.if.max-w-1024 {
  max-width: 64rem;
}

.if.min-w-1024 {
  min-width: 64rem;
}

.if.max-h-1024 {
  max-height: 64rem;
}

.if.min-h-1024 {
  min-height: 64rem;
}

.if.max-8 {
  max-width: 0.5rem;
  max-height: 0.5rem;
}

.if.min-8 {
  min-width: 0.5rem;
  min-height: 0.5rem;
}

.if.max-10 {
  max-width: 0.625rem;
  max-height: 0.625rem;
}

.if.min-10 {
  min-width: 0.625rem;
  min-height: 0.625rem;
}

.if.max-12 {
  max-width: 0.75rem;
  max-height: 0.75rem;
}

.if.min-12 {
  min-width: 0.75rem;
  min-height: 0.75rem;
}

.if.max-16 {
  max-width: 1rem;
  max-height: 1rem;
}

.if.min-16 {
  min-width: 1rem;
  min-height: 1rem;
}

.if.max-18 {
  max-width: 1.125rem;
  max-height: 1.125rem;
}

.if.min-18 {
  min-width: 1.125rem;
  min-height: 1.125rem;
}

.if.max-20 {
  max-width: 1.25rem;
  max-height: 1.25rem;
}

.if.min-20 {
  min-width: 1.25rem;
  min-height: 1.25rem;
}

.if.max-24 {
  max-width: 1.5rem;
  max-height: 1.5rem;
}

.if.min-24 {
  min-width: 1.5rem;
  min-height: 1.5rem;
}

.if.max-32 {
  max-width: 2rem;
  max-height: 2rem;
}

.if.min-32 {
  min-width: 2rem;
  min-height: 2rem;
}

.if.max-36 {
  max-width: 2.25rem;
  max-height: 2.25rem;
}

.if.min-36 {
  min-width: 2.25rem;
  min-height: 2.25rem;
}

.if.max-40 {
  max-width: 2.5rem;
  max-height: 2.5rem;
}

.if.min-40 {
  min-width: 2.5rem;
  min-height: 2.5rem;
}

.if.max-48 {
  max-width: 3rem;
  max-height: 3rem;
}

.if.min-48 {
  min-width: 3rem;
  min-height: 3rem;
}

.if.max-64 {
  max-width: 4rem;
  max-height: 4rem;
}

.if.min-64 {
  min-width: 4rem;
  min-height: 4rem;
}

.if.max-72 {
  max-width: 4.5rem;
  max-height: 4.5rem;
}

.if.min-72 {
  min-width: 4.5rem;
  min-height: 4.5rem;
}

.if.max-96 {
  max-width: 6rem;
  max-height: 6rem;
}

.if.min-96 {
  min-width: 6rem;
  min-height: 6rem;
}

.if.max-128 {
  max-width: 8rem;
  max-height: 8rem;
}

.if.min-128 {
  min-width: 8rem;
  min-height: 8rem;
}

.if.max-144 {
  max-width: 9rem;
  max-height: 9rem;
}

.if.min-144 {
  min-width: 9rem;
  min-height: 9rem;
}

.if.max-192 {
  max-width: 12rem;
  max-height: 12rem;
}

.if.min-192 {
  min-width: 12rem;
  min-height: 12rem;
}

.if.max-256 {
  max-width: 16rem;
  max-height: 16rem;
}

.if.min-256 {
  min-width: 16rem;
  min-height: 16rem;
}

.if.max-384 {
  max-width: 24rem;
  max-height: 24rem;
}

.if.min-384 {
  min-width: 24rem;
  min-height: 24rem;
}

.if.max-512 {
  max-width: 32rem;
  max-height: 32rem;
}

.if.min-512 {
  min-width: 32rem;
  min-height: 32rem;
}

.if.max-768 {
  max-width: 48rem;
  max-height: 48rem;
}

.if.min-768 {
  min-width: 48rem;
  min-height: 48rem;
}

.if.max-1024 {
  max-width: 64rem;
  max-height: 64rem;
}

.if.min-1024 {
  min-width: 64rem;
  min-height: 64rem;
}

.if.max-w-25p {
  max-width: 25%;
}

.if.min-w-25p {
  min-width: 25%;
}

.if.max-h-25p {
  max-height: 25%;
}

.if.min-h-25p {
  min-height: 25%;
}

.if.max-w-33p {
  max-width: 33.33%;
}

.if.min-w-33p {
  min-width: 33.33%;
}

.if.max-h-33p {
  max-height: 33.33%;
}

.if.min-h-33p {
  min-height: 33.33%;
}

.if.max-w-50p {
  max-width: 50%;
}

.if.min-w-50p {
  min-width: 50%;
}

.if.max-h-50p {
  max-height: 50%;
}

.if.min-h-50p {
  min-height: 50%;
}

.if.max-w-66p {
  max-width: 66.67%;
}

.if.min-w-66p {
  min-width: 66.67%;
}

.if.max-h-66p {
  max-height: 66.67%;
}

.if.min-h-66p {
  min-height: 66.67%;
}

.if.max-w-75p {
  max-width: 75%;
}

.if.min-w-75p {
  min-width: 75%;
}

.if.max-h-75p {
  max-height: 75%;
}

.if.min-h-75p {
  min-height: 75%;
}

.if.max-w-100p {
  max-width: 100%;
}

.if.min-w-100p {
  min-width: 100%;
}

.if.max-h-100p {
  max-height: 100%;
}

.if.min-h-100p {
  min-height: 100%;
}

.if.fit-content {
  width: fit-content;
  height: fit-content;
}

.if.row-gap-2 {
  row-gap: 0.125rem;
}

.if.col-gap-2 {
  column-gap: 0.125rem;
}

.if.row-gap-4 {
  row-gap: 0.25rem;
}

.if.col-gap-4 {
  column-gap: 0.25rem;
}

.if.row-gap-8 {
  row-gap: 0.5rem;
}

.if.col-gap-8 {
  column-gap: 0.5rem;
}

.if.row-gap-12 {
  row-gap: 0.75rem;
}

.if.col-gap-12 {
  column-gap: 0.75rem;
}

.if.row-gap-16 {
  row-gap: 1rem;
}

.if.col-gap-16 {
  column-gap: 1rem;
}

.if.row-gap-20 {
  row-gap: 1.25rem;
}

.if.col-gap-20 {
  column-gap: 1.25rem;
}

.if.row-gap-24 {
  row-gap: 1.5rem;
}

.if.col-gap-24 {
  column-gap: 1.5rem;
}

.if.row-gap-32 {
  row-gap: 2rem;
}

.if.col-gap-32 {
  column-gap: 2rem;
}

.if.row-gap-40 {
  row-gap: 2.5rem;
}

.if.col-gap-40 {
  column-gap: 2.5rem;
}

.if.row-gap-48 {
  row-gap: 3rem;
}

.if.col-gap-48 {
  column-gap: 3rem;
}

.if.row-gap-64 {
  row-gap: 4rem;
}

.if.col-gap-64 {
  column-gap: 4rem;
}

.if.row-gap-88 {
  row-gap: 5.5rem;
}

.if.col-gap-88 {
  column-gap: 5.5rem;
}

.if.row-gap-120 {
  row-gap: 7.5rem;
}

.if.col-gap-120 {
  column-gap: 7.5rem;
}

.if.gap-2 {
  gap: 0.125rem;
}

.if.gap-4 {
  gap: 0.25rem;
}

.if.gap-8 {
  gap: 0.5rem;
}

.if.gap-12 {
  gap: 0.75rem;
}

.if.gap-16 {
  gap: 1rem;
}

.if.gap-20 {
  gap: 1.25rem;
}

.if.gap-24 {
  gap: 1.5rem;
}

.if.gap-32 {
  gap: 2rem;
}

.if.gap-40 {
  gap: 2.5rem;
}

.if.gap-48 {
  gap: 3rem;
}

.if.gap-64 {
  gap: 4rem;
}

.if.gap-88 {
  gap: 5.5rem;
}

.if.gap-120 {
  gap: 7.5rem;
}

.if.no-gap {
  gap: 0;
}

.if.border-width-3 {
  border-width: 0.1875rem;
}

.if.border-width-2 {
  border-width: 0.125rem;
}

.if.border-width-1-5 {
  border-width: 0.09375rem;
}

.if.border-width-1-2 {
  border-width: 0.075rem;
}

.if.border-width-1 {
  border-width: 0.0625rem;
}

.if.border-width-0-5 {
  border-width: 0.03125rem;
}

.if.border-top-width-3 {
  border-top-width: 0.1875rem;
}

.if.border-top-width-2 {
  border-top-width: 0.125rem;
}

.if.border-top-width-1-5 {
  border-top-width: 0.09375rem;
}

.if.border-top-width-1-2 {
  border-top-width: 0.075rem;
}

.if.border-top-width-1 {
  border-top-width: 0.0625rem;
}

.if.border-top-width-0-5 {
  border-top-width: 0.03125rem;
}

.if.border-bottom-width-3 {
  border-bottom-width: 0.1875rem;
}

.if.border-bottom-width-2 {
  border-bottom-width: 0.125rem;
}

.if.border-bottom-width-1-5 {
  border-bottom-width: 0.09375rem;
}

.if.border-bottom-width-1-2 {
  border-bottom-width: 0.075rem;
}

.if.border-bottom-width-1 {
  border-bottom-width: 0.0625rem;
}

.if.border-bottom-width-0-5 {
  border-bottom-width: 0.03125rem;
}

.if.border-right-width-3 {
  border-right-width: 0.1875rem;
}

.if.border-right-width-2 {
  border-right-width: 0.125rem;
}

.if.border-right-width-1-5 {
  border-right-width: 0.09375rem;
}

.if.border-right-width-1-2 {
  border-right-width: 0.075rem;
}

.if.border-right-width-1 {
  border-right-width: 0.0625rem;
}

.if.border-right-width-0-5 {
  border-right-width: 0.03125rem;
}

.if.border-left-width-3 {
  border-left-width: 0.1875rem;
}

.if.border-left-width-2 {
  border-left-width: 0.125rem;
}

.if.border-left-width-1-5 {
  border-left-width: 0.09375rem;
}

.if.border-left-width-1-2 {
  border-left-width: 0.075rem;
}

.if.border-left-width-1 {
  border-left-width: 0.0625rem;
}

.if.border-left-width-0-5 {
  border-left-width: 0.03125rem;
}

.if.border-none {
  border: none;
}

.if.border-dashed {
  border-style: dashed;
}

.if.border-solid {
  border-style: solid;
}

.if.border-dotted {
  border-style: dotted;
}

.if.border-top-dashed {
  border-top-style: dashed;
}

.if.border-top-solid {
  border-top-style: solid;
}

.if.border-top-dotted {
  border-top-style: dotted;
}

.if.border-bottom-dashed {
  border-bottom-style: dashed;
}

.if.border-bottom-solid {
  border-bottom-style: solid;
}

.if.border-bottom-dotted {
  border-bottom-style: dotted;
}

.if.border-right-dashed {
  border-right-style: dashed;
}

.if.border-right-solid {
  border-right-style: solid;
}

.if.border-right-dotted {
  border-right-style: dotted;
}

.if.border-left-dashed {
  border-left-style: dashed;
}

.if.border-left-solid {
  border-left-style: solid;
}

.if.border-left-dotted {
  border-left-style: dotted;
}

.if.border-radius-12 {
  border-radius: 0.75rem;
}

.if.border-radius-6 {
  border-radius: 0.375rem;
}

.if.border-radius-4 {
  border-radius: 0.25rem;
}

.if.border-radius-0 {
  border-radius: 0;
}

.if.border-radius-50p, .if.round {
  border-radius: 50%;
}

.if.shadow-400 {
  box-shadow: 0 8px 24px 0 rgba(51, 30, 17, 0.1411764706);
}

.if.shadow-300 {
  box-shadow: 0 8px 12px 0 rgba(51, 30, 17, 0.1215686275);
}

.if.shadow-200 {
  box-shadow: 0 4px 8px 0 rgba(51, 30, 17, 0.1019607843);
}

.if.background-yellow-500 {
  background-color: #E9AD25;
}

.if.color-yellow-500 {
  color: #E9AD25;
}

.if.border-color-yellow-500 {
  border-color: #E9AD25;
}

.if.background-yellow-400 {
  background-color: #FCC74F;
}

.if.color-yellow-400 {
  color: #FCC74F;
}

.if.border-color-yellow-400 {
  border-color: #FCC74F;
}

.if.background-yellow-300 {
  background-color: #F8D077;
}

.if.color-yellow-300 {
  color: #F8D077;
}

.if.border-color-yellow-300 {
  border-color: #F8D077;
}

.if.background-yellow-200 {
  background-color: #F7DFA1;
}

.if.color-yellow-200 {
  color: #F7DFA1;
}

.if.border-color-yellow-200 {
  border-color: #F7DFA1;
}

.if.background-yellow-100 {
  background-color: #F9EBC7;
}

.if.color-yellow-100 {
  color: #F9EBC7;
}

.if.border-color-yellow-100 {
  border-color: #F9EBC7;
}

.if.background-brown-900 {
  background-color: #24150C;
}

.if.color-brown-900 {
  color: #24150C;
}

.if.border-color-brown-900 {
  border-color: #24150C;
}

.if.background-brown-800 {
  background-color: #331E11;
}

.if.color-brown-800 {
  color: #331E11;
}

.if.border-color-brown-800 {
  border-color: #331E11;
}

.if.background-brown-700 {
  background-color: #6E625E;
}

.if.color-brown-700 {
  color: #6E625E;
}

.if.border-color-brown-700 {
  border-color: #6E625E;
}

.if.background-brown-600 {
  background-color: #9F948F;
}

.if.color-brown-600 {
  color: #9F948F;
}

.if.border-color-brown-600 {
  border-color: #9F948F;
}

.if.background-brown-500 {
  background-color: #B7AEA8;
}

.if.color-brown-500 {
  color: #B7AEA8;
}

.if.border-color-brown-500 {
  border-color: #B7AEA8;
}

.if.background-beige-600 {
  background-color: #D6CEC8;
}

.if.color-beige-600 {
  color: #D6CEC8;
}

.if.border-color-beige-600 {
  border-color: #D6CEC8;
}

.if.background-beige-500 {
  background-color: #E8E0D9;
}

.if.color-beige-500 {
  color: #E8E0D9;
}

.if.border-color-beige-500 {
  border-color: #E8E0D9;
}

.if.background-beige-400 {
  background-color: #EDE6E1;
}

.if.color-beige-400 {
  color: #EDE6E1;
}

.if.border-color-beige-400 {
  border-color: #EDE6E1;
}

.if.background-beige-300 {
  background-color: #F1ECE8;
}

.if.color-beige-300 {
  color: #F1ECE8;
}

.if.border-color-beige-300 {
  border-color: #F1ECE8;
}

.if.background-beige-200 {
  background-color: #F6F3F0;
}

.if.color-beige-200 {
  color: #F6F3F0;
}

.if.border-color-beige-200 {
  border-color: #F6F3F0;
}

.if.background-beige-100 {
  background-color: #FAF9F7;
}

.if.color-beige-100 {
  color: #FAF9F7;
}

.if.border-color-beige-100 {
  border-color: #FAF9F7;
}

.if.background-blue-500 {
  background-color: #1241A2;
}

.if.color-blue-500 {
  color: #1241A2;
}

.if.border-color-blue-500 {
  border-color: #1241A2;
}

.if.background-blue-400 {
  background-color: #0054F0;
}

.if.color-blue-400 {
  color: #0054F0;
}

.if.border-color-blue-400 {
  border-color: #0054F0;
}

.if.background-blue-300 {
  background-color: #5A8AE2;
}

.if.color-blue-300 {
  color: #5A8AE2;
}

.if.border-color-blue-300 {
  border-color: #5A8AE2;
}

.if.background-blue-200 {
  background-color: #A8C2F0;
}

.if.color-blue-200 {
  color: #A8C2F0;
}

.if.border-color-blue-200 {
  border-color: #A8C2F0;
}

.if.background-blue-100 {
  background-color: #E1E9F6;
}

.if.color-blue-100 {
  color: #E1E9F6;
}

.if.border-color-blue-100 {
  border-color: #E1E9F6;
}

.if.background-red-500 {
  background-color: #812308;
}

.if.color-red-500 {
  color: #812308;
}

.if.border-color-red-500 {
  border-color: #812308;
}

.if.background-red-400 {
  background-color: #BB320C;
}

.if.color-red-400 {
  color: #BB320C;
}

.if.border-color-red-400 {
  border-color: #BB320C;
}

.if.background-red-300 {
  background-color: #D16547;
}

.if.color-red-300 {
  color: #D16547;
}

.if.border-color-red-300 {
  border-color: #D16547;
}

.if.background-red-200 {
  background-color: #F1B398;
}

.if.color-red-200 {
  color: #F1B398;
}

.if.border-color-red-200 {
  border-color: #F1B398;
}

.if.background-red-100 {
  background-color: #F9E3D4;
}

.if.color-red-100 {
  color: #F9E3D4;
}

.if.border-color-red-100 {
  border-color: #F9E3D4;
}

.if.background-green-500 {
  background-color: #183F1F;
}

.if.color-green-500 {
  color: #183F1F;
}

.if.border-color-green-500 {
  border-color: #183F1F;
}

.if.background-green-400 {
  background-color: #217331;
}

.if.color-green-400 {
  color: #217331;
}

.if.border-color-green-400 {
  border-color: #217331;
}

.if.background-green-300 {
  background-color: #4B9B5A;
}

.if.color-green-300 {
  color: #4B9B5A;
}

.if.border-color-green-300 {
  border-color: #4B9B5A;
}

.if.background-green-200 {
  background-color: #B6D5B5;
}

.if.color-green-200 {
  color: #B6D5B5;
}

.if.border-color-green-200 {
  border-color: #B6D5B5;
}

.if.background-green-100 {
  background-color: #E0F0DB;
}

.if.color-green-100 {
  color: #E0F0DB;
}

.if.border-color-green-100 {
  border-color: #E0F0DB;
}

.if.background-white {
  background-color: #FFFFFF;
}

.if.color-white {
  color: #FFFFFF;
}

.if.border-color-white {
  border-color: #FFFFFF;
}

.if.background-black {
  background-color: black;
}

.if.color-black {
  color: black;
}

.if.border-color-black {
  border-color: black;
}

.if.background-transparent {
  background-color: transparent;
}

.if.color-transparent {
  color: transparent;
}

.if.border-color-transparent {
  border-color: transparent;
}

.if.background-inherit {
  background-color: inherit;
}

.if.color-inherit {
  color: inherit;
}

.if.border-color-inherit {
  border-color: inherit;
}

.if.opacity-0 {
  opacity: 0;
}

.if.opacity-10 {
  opacity: 0.1;
}

.if.opacity-20 {
  opacity: 0.2;
}

.if.opacity-30 {
  opacity: 0.3;
}

.if.opacity-40 {
  opacity: 0.4;
}

.if.opacity-50 {
  opacity: 0.5;
}

.if.opacity-60 {
  opacity: 0.6;
}

.if.opacity-70 {
  opacity: 0.7;
}

.if.opacity-80 {
  opacity: 0.8;
}

.if.opacity-90 {
  opacity: 0.9;
}

.if.opacity-100 {
  opacity: 1;
}

.if.mask-0::before {
  opacity: 0;
}

.if.mask-10::before {
  opacity: 0.1;
}

.if.mask-20::before {
  opacity: 0.2;
}

.if.mask-30::before {
  opacity: 0.3;
}

.if.mask-40::before {
  opacity: 0.4;
}

.if.mask-50::before {
  opacity: 0.5;
}

.if.mask-60::before {
  opacity: 0.6;
}

.if.mask-70::before {
  opacity: 0.7;
}

.if.mask-80::before {
  opacity: 0.8;
}

.if.mask-90::before {
  opacity: 0.9;
}

.if.mask-100::before {
  opacity: 1;
}

.if.dim,
.if.brighten {
  position: relative;
}

.if.dim::before,
.if.brighten::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.if.dim::before {
  background-color: black;
}

.if.brighten::before {
  background-color: white;
}

.if.blur-0 {
  filter: blur(0px);
}

.if.blur-1 {
  filter: blur(1px);
}

.if.blur-2 {
  filter: blur(2px);
}

.if.blur-3 {
  filter: blur(3px);
}

.if.blur-4 {
  filter: blur(4px);
}

.if.blur-5 {
  filter: blur(5px);
}

.if.blur-6 {
  filter: blur(6px);
}

.if.blur-7 {
  filter: blur(7px);
}

.if.blur-8 {
  filter: blur(8px);
}

.if.blur-9 {
  filter: blur(9px);
}

.if.blur-10 {
  filter: blur(10px);
}

.if.cursor-none {
  cursor: none;
}

.if.cursor-context-menu {
  cursor: context-menu;
}

.if.cursor-help {
  cursor: help;
}

.if.cursor-pointer {
  cursor: pointer;
}

.if.cursor-progress {
  cursor: progress;
}

.if.cursor-text {
  cursor: text;
}

.if.cursor-not-allowed {
  cursor: not-allowed;
}

.if.cursor-zoom-in {
  cursor: zoom-in;
}

.if.cursor-zoom-out {
  cursor: zoom-out;
}

.if.object-contain,
.if.object-fit-contain {
  object-fit: contain;
}

.if.object-cover,
.if.object-fit-cover {
  object-fit: cover;
}

.if.object-fill,
.if.object-fit-fill {
  object-fit: fill;
}

.if.object-none,
.if.object-fit-none {
  object-fit: none;
}

.if.object-scale-down,
.if.object-fit-scale-down {
  object-fit: scale-down;
}

.if.text-wrap {
  text-wrap: wrap;
}

.if.text-nowrap {
  text-wrap: nowrap;
}

.if.text-wrap-balance {
  text-wrap: balance;
}

.if.text-wrap-pretty {
  text-wrap: pretty;
}

.if.sr-only {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
}

@media screen and (min-width: 43.75rem) {
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content */
  .if.md-justify-content-center {
    justify-content: center;
  }
  .if.md-justify-content-start {
    justify-content: start;
  }
  .if.md-justify-content-end {
    justify-content: end;
  }
  .if.md-justify-content-left {
    justify-content: left;
  }
  .if.md-justify-content-right {
    justify-content: right;
  }
  .if.md-justify-content-space-between {
    justify-content: space-between;
  }
  .if.md-justify-content-space-around {
    justify-content: space-around;
  }
  .if.md-justify-content-space-evenly {
    justify-content: space-evenly;
  }
  .if.md-justify-content-stretch {
    justify-content: stretch;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-items */
  .if.md-justify-items-normal {
    justify-items: normal;
  }
  .if.md-justify-items-stretch {
    justify-items: stretch;
  }
  .if.md-justify-items-center {
    justify-items: center;
  }
  .if.md-justify-items-start {
    justify-items: start;
  }
  .if.md-justify-items-end {
    justify-items: end;
  }
  .if.md-justify-items-left {
    justify-items: left;
  }
  .if.md-justify-items-right {
    justify-items: right;
  }
  .if.md-justify-items-baseline {
    justify-items: baseline;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-self */
  .if.md-justify-self-auto {
    justify-self: auto;
  }
  .if.md-justify-self-normal {
    justify-self: normal;
  }
  .if.md-justify-self-stretch {
    justify-self: stretch;
  }
  .if.md-justify-self-center {
    justify-self: center;
  }
  .if.md-justify-self-start {
    justify-self: start;
  }
  .if.md-justify-self-end {
    justify-self: end;
  }
  .if.md-justify-self-left {
    justify-self: left;
  }
  .if.md-justify-self-right {
    justify-self: right;
  }
  .if.md-justify-self-baseline {
    justify-self: baseline;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/align-content */
  .if.md-align-content-center {
    align-content: center;
  }
  .if.md-align-content-start {
    align-content: start;
  }
  .if.md-align-content-end {
    align-content: end;
  }
  .if.md-align-content-baseline {
    align-content: baseline;
  }
  .if.md-align-content-space-between {
    align-content: space-between;
  }
  .if.md-align-content-space-around {
    align-content: space-around;
  }
  .if.md-align-content-space-evenly {
    align-content: space-evenly;
  }
  .if.md-align-content-stretch {
    align-content: stretch;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/align-items */
  .if.md-align-items-center {
    align-items: center;
  }
  .if.md-align-items-start {
    align-items: start;
  }
  .if.md-align-items-end {
    align-items: end;
  }
  .if.md-align-items-baseline {
    align-items: baseline;
  }
  .if.md-align-items-stretch {
    align-items: stretch;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/align-self */
  .if.md-align-self-auto {
    align-self: auto;
  }
  .if.md-align-self-center {
    align-self: center;
  }
  .if.md-align-self-start {
    align-self: start;
  }
  .if.md-align-self-end {
    align-self: end;
  }
  .if.md-align-self-baseline {
    align-self: baseline;
  }
  .if.md-align-self-stretch {
    align-self: stretch;
  }
  .if.md-grid-container {
    display: grid;
  }
  .if.md-flex {
    display: flex;
  }
  .if.md-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .if.md-flex-row {
    display: flex;
    flex-direction: row;
  }
  .if.md-flex-column {
    display: flex;
    flex-direction: column;
  }
  .if.md-flex-wrap {
    flex-wrap: wrap;
  }
  .if.md-flex-no-wrap {
    flex-wrap: nowrap;
  }
  .if.md-flex-grow {
    flex-grow: 1;
  }
  .if.md-flex-grow-0 {
    flex-grow: 0;
  }
  .if.md-flex-shrink {
    flex-shrink: 1;
  }
  .if.md-flex-shrink-0 {
    flex-shrink: 0;
  }
  .if.md-position-relative {
    position: relative;
  }
  .if.md-position-absolute {
    position: absolute;
  }
  .if.md-display-none {
    display: none;
  }
  .if.md-display-block {
    display: block;
  }
  .if.md-overflow-hidden {
    overflow: hidden;
  }
  .if.md-overflow-scroll {
    overflow: scroll;
  }
  .if.md-overflow-y-scroll {
    overflow-y: scroll;
  }
  .if.md-overflow-x-scroll {
    overflow-x: scroll;
  }
  .if.md-m-2 {
    margin: 0.125rem;
  }
  .if.md-my-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .if.md-mx-2 {
    margin-right: 0.125rem;
    margin-left: 0.125rem;
  }
  .if.md-m-4 {
    margin: 0.25rem;
  }
  .if.md-my-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .if.md-mx-4 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .if.md-m-8 {
    margin: 0.5rem;
  }
  .if.md-my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .if.md-mx-8 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .if.md-m-12 {
    margin: 0.75rem;
  }
  .if.md-my-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .if.md-mx-12 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .if.md-m-16 {
    margin: 1rem;
  }
  .if.md-my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .if.md-mx-16 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .if.md-m-20 {
    margin: 1.25rem;
  }
  .if.md-my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .if.md-mx-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .if.md-m-24 {
    margin: 1.5rem;
  }
  .if.md-my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .if.md-mx-24 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .if.md-m-32 {
    margin: 2rem;
  }
  .if.md-my-32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .if.md-mx-32 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .if.md-m-40 {
    margin: 2.5rem;
  }
  .if.md-my-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .if.md-mx-40 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .if.md-m-48 {
    margin: 3rem;
  }
  .if.md-my-48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .if.md-mx-48 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .if.md-m-64 {
    margin: 4rem;
  }
  .if.md-my-64 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .if.md-mx-64 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .if.md-m-88 {
    margin: 5.5rem;
  }
  .if.md-my-88 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
  }
  .if.md-mx-88 {
    margin-right: 5.5rem;
    margin-left: 5.5rem;
  }
  .if.md-m-120 {
    margin: 7.5rem;
  }
  .if.md-my-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .if.md-mx-120 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .if.md-p-2 {
    padding: 0.125rem;
  }
  .if.md-py-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .if.md-px-2 {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
  }
  .if.md-p-4 {
    padding: 0.25rem;
  }
  .if.md-py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .if.md-px-4 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .if.md-p-8 {
    padding: 0.5rem;
  }
  .if.md-py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .if.md-px-8 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .if.md-p-12 {
    padding: 0.75rem;
  }
  .if.md-py-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .if.md-px-12 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .if.md-p-16 {
    padding: 1rem;
  }
  .if.md-py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .if.md-px-16 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .if.md-p-20 {
    padding: 1.25rem;
  }
  .if.md-py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .if.md-px-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .if.md-p-24 {
    padding: 1.5rem;
  }
  .if.md-py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .if.md-px-24 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .if.md-p-32 {
    padding: 2rem;
  }
  .if.md-py-32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .if.md-px-32 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .if.md-p-40 {
    padding: 2.5rem;
  }
  .if.md-py-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .if.md-px-40 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .if.md-p-48 {
    padding: 3rem;
  }
  .if.md-py-48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .if.md-px-48 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .if.md-p-64 {
    padding: 4rem;
  }
  .if.md-py-64 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .if.md-px-64 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .if.md-p-88 {
    padding: 5.5rem;
  }
  .if.md-py-88 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .if.md-px-88 {
    padding-right: 5.5rem;
    padding-left: 5.5rem;
  }
  .if.md-p-120 {
    padding: 7.5rem;
  }
  .if.md-py-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .if.md-px-120 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .if.md-mt-2 {
    margin-top: 0.125rem;
  }
  .if.md-mb-2 {
    margin-bottom: 0.125rem;
  }
  .if.md-mr-2 {
    margin-right: 0.125rem;
  }
  .if.md-ml-2 {
    margin-left: 0.125rem;
  }
  .if.md-mt-4 {
    margin-top: 0.25rem;
  }
  .if.md-mb-4 {
    margin-bottom: 0.25rem;
  }
  .if.md-mr-4 {
    margin-right: 0.25rem;
  }
  .if.md-ml-4 {
    margin-left: 0.25rem;
  }
  .if.md-mt-8 {
    margin-top: 0.5rem;
  }
  .if.md-mb-8 {
    margin-bottom: 0.5rem;
  }
  .if.md-mr-8 {
    margin-right: 0.5rem;
  }
  .if.md-ml-8 {
    margin-left: 0.5rem;
  }
  .if.md-mt-12 {
    margin-top: 0.75rem;
  }
  .if.md-mb-12 {
    margin-bottom: 0.75rem;
  }
  .if.md-mr-12 {
    margin-right: 0.75rem;
  }
  .if.md-ml-12 {
    margin-left: 0.75rem;
  }
  .if.md-mt-16 {
    margin-top: 1rem;
  }
  .if.md-mb-16 {
    margin-bottom: 1rem;
  }
  .if.md-mr-16 {
    margin-right: 1rem;
  }
  .if.md-ml-16 {
    margin-left: 1rem;
  }
  .if.md-mt-20 {
    margin-top: 1.25rem;
  }
  .if.md-mb-20 {
    margin-bottom: 1.25rem;
  }
  .if.md-mr-20 {
    margin-right: 1.25rem;
  }
  .if.md-ml-20 {
    margin-left: 1.25rem;
  }
  .if.md-mt-24 {
    margin-top: 1.5rem;
  }
  .if.md-mb-24 {
    margin-bottom: 1.5rem;
  }
  .if.md-mr-24 {
    margin-right: 1.5rem;
  }
  .if.md-ml-24 {
    margin-left: 1.5rem;
  }
  .if.md-mt-32 {
    margin-top: 2rem;
  }
  .if.md-mb-32 {
    margin-bottom: 2rem;
  }
  .if.md-mr-32 {
    margin-right: 2rem;
  }
  .if.md-ml-32 {
    margin-left: 2rem;
  }
  .if.md-mt-40 {
    margin-top: 2.5rem;
  }
  .if.md-mb-40 {
    margin-bottom: 2.5rem;
  }
  .if.md-mr-40 {
    margin-right: 2.5rem;
  }
  .if.md-ml-40 {
    margin-left: 2.5rem;
  }
  .if.md-mt-48 {
    margin-top: 3rem;
  }
  .if.md-mb-48 {
    margin-bottom: 3rem;
  }
  .if.md-mr-48 {
    margin-right: 3rem;
  }
  .if.md-ml-48 {
    margin-left: 3rem;
  }
  .if.md-mt-64 {
    margin-top: 4rem;
  }
  .if.md-mb-64 {
    margin-bottom: 4rem;
  }
  .if.md-mr-64 {
    margin-right: 4rem;
  }
  .if.md-ml-64 {
    margin-left: 4rem;
  }
  .if.md-mt-88 {
    margin-top: 5.5rem;
  }
  .if.md-mb-88 {
    margin-bottom: 5.5rem;
  }
  .if.md-mr-88 {
    margin-right: 5.5rem;
  }
  .if.md-ml-88 {
    margin-left: 5.5rem;
  }
  .if.md-mt-120 {
    margin-top: 7.5rem;
  }
  .if.md-mb-120 {
    margin-bottom: 7.5rem;
  }
  .if.md-mr-120 {
    margin-right: 7.5rem;
  }
  .if.md-ml-120 {
    margin-left: 7.5rem;
  }
  .if.md-pt-2 {
    padding-top: 0.125rem;
  }
  .if.md-pb-2 {
    padding-bottom: 0.125rem;
  }
  .if.md-pr-2 {
    padding-right: 0.125rem;
  }
  .if.md-pl-2 {
    padding-left: 0.125rem;
  }
  .if.md-pt-4 {
    padding-top: 0.25rem;
  }
  .if.md-pb-4 {
    padding-bottom: 0.25rem;
  }
  .if.md-pr-4 {
    padding-right: 0.25rem;
  }
  .if.md-pl-4 {
    padding-left: 0.25rem;
  }
  .if.md-pt-8 {
    padding-top: 0.5rem;
  }
  .if.md-pb-8 {
    padding-bottom: 0.5rem;
  }
  .if.md-pr-8 {
    padding-right: 0.5rem;
  }
  .if.md-pl-8 {
    padding-left: 0.5rem;
  }
  .if.md-pt-12 {
    padding-top: 0.75rem;
  }
  .if.md-pb-12 {
    padding-bottom: 0.75rem;
  }
  .if.md-pr-12 {
    padding-right: 0.75rem;
  }
  .if.md-pl-12 {
    padding-left: 0.75rem;
  }
  .if.md-pt-16 {
    padding-top: 1rem;
  }
  .if.md-pb-16 {
    padding-bottom: 1rem;
  }
  .if.md-pr-16 {
    padding-right: 1rem;
  }
  .if.md-pl-16 {
    padding-left: 1rem;
  }
  .if.md-pt-20 {
    padding-top: 1.25rem;
  }
  .if.md-pb-20 {
    padding-bottom: 1.25rem;
  }
  .if.md-pr-20 {
    padding-right: 1.25rem;
  }
  .if.md-pl-20 {
    padding-left: 1.25rem;
  }
  .if.md-pt-24 {
    padding-top: 1.5rem;
  }
  .if.md-pb-24 {
    padding-bottom: 1.5rem;
  }
  .if.md-pr-24 {
    padding-right: 1.5rem;
  }
  .if.md-pl-24 {
    padding-left: 1.5rem;
  }
  .if.md-pt-32 {
    padding-top: 2rem;
  }
  .if.md-pb-32 {
    padding-bottom: 2rem;
  }
  .if.md-pr-32 {
    padding-right: 2rem;
  }
  .if.md-pl-32 {
    padding-left: 2rem;
  }
  .if.md-pt-40 {
    padding-top: 2.5rem;
  }
  .if.md-pb-40 {
    padding-bottom: 2.5rem;
  }
  .if.md-pr-40 {
    padding-right: 2.5rem;
  }
  .if.md-pl-40 {
    padding-left: 2.5rem;
  }
  .if.md-pt-48 {
    padding-top: 3rem;
  }
  .if.md-pb-48 {
    padding-bottom: 3rem;
  }
  .if.md-pr-48 {
    padding-right: 3rem;
  }
  .if.md-pl-48 {
    padding-left: 3rem;
  }
  .if.md-pt-64 {
    padding-top: 4rem;
  }
  .if.md-pb-64 {
    padding-bottom: 4rem;
  }
  .if.md-pr-64 {
    padding-right: 4rem;
  }
  .if.md-pl-64 {
    padding-left: 4rem;
  }
  .if.md-pt-88 {
    padding-top: 5.5rem;
  }
  .if.md-pb-88 {
    padding-bottom: 5.5rem;
  }
  .if.md-pr-88 {
    padding-right: 5.5rem;
  }
  .if.md-pl-88 {
    padding-left: 5.5rem;
  }
  .if.md-pt-120 {
    padding-top: 7.5rem;
  }
  .if.md-pb-120 {
    padding-bottom: 7.5rem;
  }
  .if.md-pr-120 {
    padding-right: 7.5rem;
  }
  .if.md-pl-120 {
    padding-left: 7.5rem;
  }
  .if.md-no-padding {
    padding: 0;
  }
  .if.md-no-margin {
    margin: 0;
  }
  .if.md-mx-auto, .if.md-mh-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .if.md-size-8 {
    width: 0.5rem;
    height: 0.5rem;
  }
  .if.md-size-10 {
    width: 0.625rem;
    height: 0.625rem;
  }
  .if.md-size-12 {
    width: 0.75rem;
    height: 0.75rem;
  }
  .if.md-size-16 {
    width: 1rem;
    height: 1rem;
  }
  .if.md-size-18 {
    width: 1.125rem;
    height: 1.125rem;
  }
  .if.md-size-20 {
    width: 1.25rem;
    height: 1.25rem;
  }
  .if.md-size-24 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .if.md-size-32 {
    width: 2rem;
    height: 2rem;
  }
  .if.md-size-36 {
    width: 2.25rem;
    height: 2.25rem;
  }
  .if.md-size-40 {
    width: 2.5rem;
    height: 2.5rem;
  }
  .if.md-size-48 {
    width: 3rem;
    height: 3rem;
  }
  .if.md-size-64 {
    width: 4rem;
    height: 4rem;
  }
  .if.md-size-72 {
    width: 4.5rem;
    height: 4.5rem;
  }
  .if.md-size-96 {
    width: 6rem;
    height: 6rem;
  }
  .if.md-size-128 {
    width: 8rem;
    height: 8rem;
  }
  .if.md-size-144 {
    width: 9rem;
    height: 9rem;
  }
  .if.md-size-192 {
    width: 12rem;
    height: 12rem;
  }
  .if.md-size-256 {
    width: 16rem;
    height: 16rem;
  }
  .if.md-size-384 {
    width: 24rem;
    height: 24rem;
  }
  .if.md-size-512 {
    width: 32rem;
    height: 32rem;
  }
  .if.md-size-768 {
    width: 48rem;
    height: 48rem;
  }
  .if.md-size-1024 {
    width: 64rem;
    height: 64rem;
  }
  .if.md-size-w-8 {
    width: 0.5rem;
  }
  .if.md-size-h-8 {
    height: 0.5rem;
  }
  .if.md-size-w-10 {
    width: 0.625rem;
  }
  .if.md-size-h-10 {
    height: 0.625rem;
  }
  .if.md-size-w-12 {
    width: 0.75rem;
  }
  .if.md-size-h-12 {
    height: 0.75rem;
  }
  .if.md-size-w-16 {
    width: 1rem;
  }
  .if.md-size-h-16 {
    height: 1rem;
  }
  .if.md-size-w-18 {
    width: 1.125rem;
  }
  .if.md-size-h-18 {
    height: 1.125rem;
  }
  .if.md-size-w-20 {
    width: 1.25rem;
  }
  .if.md-size-h-20 {
    height: 1.25rem;
  }
  .if.md-size-w-24 {
    width: 1.5rem;
  }
  .if.md-size-h-24 {
    height: 1.5rem;
  }
  .if.md-size-w-32 {
    width: 2rem;
  }
  .if.md-size-h-32 {
    height: 2rem;
  }
  .if.md-size-w-36 {
    width: 2.25rem;
  }
  .if.md-size-h-36 {
    height: 2.25rem;
  }
  .if.md-size-w-40 {
    width: 2.5rem;
  }
  .if.md-size-h-40 {
    height: 2.5rem;
  }
  .if.md-size-w-48 {
    width: 3rem;
  }
  .if.md-size-h-48 {
    height: 3rem;
  }
  .if.md-size-w-64 {
    width: 4rem;
  }
  .if.md-size-h-64 {
    height: 4rem;
  }
  .if.md-size-w-72 {
    width: 4.5rem;
  }
  .if.md-size-h-72 {
    height: 4.5rem;
  }
  .if.md-size-w-96 {
    width: 6rem;
  }
  .if.md-size-h-96 {
    height: 6rem;
  }
  .if.md-size-w-128 {
    width: 8rem;
  }
  .if.md-size-h-128 {
    height: 8rem;
  }
  .if.md-size-w-144 {
    width: 9rem;
  }
  .if.md-size-h-144 {
    height: 9rem;
  }
  .if.md-size-w-192 {
    width: 12rem;
  }
  .if.md-size-h-192 {
    height: 12rem;
  }
  .if.md-size-w-256 {
    width: 16rem;
  }
  .if.md-size-h-256 {
    height: 16rem;
  }
  .if.md-size-w-384 {
    width: 24rem;
  }
  .if.md-size-h-384 {
    height: 24rem;
  }
  .if.md-size-w-512 {
    width: 32rem;
  }
  .if.md-size-h-512 {
    height: 32rem;
  }
  .if.md-size-w-768 {
    width: 48rem;
  }
  .if.md-size-h-768 {
    height: 48rem;
  }
  .if.md-size-w-1024 {
    width: 64rem;
  }
  .if.md-size-h-1024 {
    height: 64rem;
  }
  .if.md-size-25p {
    width: 25%;
    height: 25%;
  }
  .if.md-size-33p {
    width: 33.33%;
    height: 33.33%;
  }
  .if.md-size-50p {
    width: 50%;
    height: 50%;
  }
  .if.md-size-66p {
    width: 66.67%;
    height: 66.67%;
  }
  .if.md-size-75p {
    width: 75%;
    height: 75%;
  }
  .if.md-size-100p {
    width: 100%;
    height: 100%;
  }
  .if.md-size-w-25p {
    width: 25%;
  }
  .if.md-size-h-25p {
    height: 25%;
  }
  .if.md-size-w-33p {
    width: 33.33%;
  }
  .if.md-size-h-33p {
    height: 33.33%;
  }
  .if.md-size-w-50p {
    width: 50%;
  }
  .if.md-size-h-50p {
    height: 50%;
  }
  .if.md-size-w-66p {
    width: 66.67%;
  }
  .if.md-size-h-66p {
    height: 66.67%;
  }
  .if.md-size-w-75p {
    width: 75%;
  }
  .if.md-size-h-75p {
    height: 75%;
  }
  .if.md-size-w-100p {
    width: 100%;
  }
  .if.md-size-h-100p {
    height: 100%;
  }
  .if.md-min-8 {
    min-width: 0.5rem;
    min-height: 0.5rem;
  }
  .if.md-max-8 {
    max-width: 0.5rem;
    max-height: 0.5rem;
  }
  .if.md-min-10 {
    min-width: 0.625rem;
    min-height: 0.625rem;
  }
  .if.md-max-10 {
    max-width: 0.625rem;
    max-height: 0.625rem;
  }
  .if.md-min-12 {
    min-width: 0.75rem;
    min-height: 0.75rem;
  }
  .if.md-max-12 {
    max-width: 0.75rem;
    max-height: 0.75rem;
  }
  .if.md-min-16 {
    min-width: 1rem;
    min-height: 1rem;
  }
  .if.md-max-16 {
    max-width: 1rem;
    max-height: 1rem;
  }
  .if.md-min-18 {
    min-width: 1.125rem;
    min-height: 1.125rem;
  }
  .if.md-max-18 {
    max-width: 1.125rem;
    max-height: 1.125rem;
  }
  .if.md-min-20 {
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
  .if.md-max-20 {
    max-width: 1.25rem;
    max-height: 1.25rem;
  }
  .if.md-min-24 {
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
  .if.md-max-24 {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }
  .if.md-min-32 {
    min-width: 2rem;
    min-height: 2rem;
  }
  .if.md-max-32 {
    max-width: 2rem;
    max-height: 2rem;
  }
  .if.md-min-36 {
    min-width: 2.25rem;
    min-height: 2.25rem;
  }
  .if.md-max-36 {
    max-width: 2.25rem;
    max-height: 2.25rem;
  }
  .if.md-min-40 {
    min-width: 2.5rem;
    min-height: 2.5rem;
  }
  .if.md-max-40 {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }
  .if.md-min-48 {
    min-width: 3rem;
    min-height: 3rem;
  }
  .if.md-max-48 {
    max-width: 3rem;
    max-height: 3rem;
  }
  .if.md-min-64 {
    min-width: 4rem;
    min-height: 4rem;
  }
  .if.md-max-64 {
    max-width: 4rem;
    max-height: 4rem;
  }
  .if.md-min-72 {
    min-width: 4.5rem;
    min-height: 4.5rem;
  }
  .if.md-max-72 {
    max-width: 4.5rem;
    max-height: 4.5rem;
  }
  .if.md-min-96 {
    min-width: 6rem;
    min-height: 6rem;
  }
  .if.md-max-96 {
    max-width: 6rem;
    max-height: 6rem;
  }
  .if.md-min-128 {
    min-width: 8rem;
    min-height: 8rem;
  }
  .if.md-max-128 {
    max-width: 8rem;
    max-height: 8rem;
  }
  .if.md-min-144 {
    min-width: 9rem;
    min-height: 9rem;
  }
  .if.md-max-144 {
    max-width: 9rem;
    max-height: 9rem;
  }
  .if.md-min-192 {
    min-width: 12rem;
    min-height: 12rem;
  }
  .if.md-max-192 {
    max-width: 12rem;
    max-height: 12rem;
  }
  .if.md-min-256 {
    min-width: 16rem;
    min-height: 16rem;
  }
  .if.md-max-256 {
    max-width: 16rem;
    max-height: 16rem;
  }
  .if.md-min-384 {
    min-width: 24rem;
    min-height: 24rem;
  }
  .if.md-max-384 {
    max-width: 24rem;
    max-height: 24rem;
  }
  .if.md-min-512 {
    min-width: 32rem;
    min-height: 32rem;
  }
  .if.md-max-512 {
    max-width: 32rem;
    max-height: 32rem;
  }
  .if.md-min-768 {
    min-width: 48rem;
    min-height: 48rem;
  }
  .if.md-max-768 {
    max-width: 48rem;
    max-height: 48rem;
  }
  .if.md-min-1024 {
    min-width: 64rem;
    min-height: 64rem;
  }
  .if.md-max-1024 {
    max-width: 64rem;
    max-height: 64rem;
  }
  .if.md-min-w-8 {
    min-width: 0.5rem;
  }
  .if.md-max-w-8 {
    max-width: 0.5rem;
  }
  .if.md-min-h-8 {
    min-height: 0.5rem;
  }
  .if.md-max-h-8 {
    max-height: 0.5rem;
  }
  .if.md-min-w-10 {
    min-width: 0.625rem;
  }
  .if.md-max-w-10 {
    max-width: 0.625rem;
  }
  .if.md-min-h-10 {
    min-height: 0.625rem;
  }
  .if.md-max-h-10 {
    max-height: 0.625rem;
  }
  .if.md-min-w-12 {
    min-width: 0.75rem;
  }
  .if.md-max-w-12 {
    max-width: 0.75rem;
  }
  .if.md-min-h-12 {
    min-height: 0.75rem;
  }
  .if.md-max-h-12 {
    max-height: 0.75rem;
  }
  .if.md-min-w-16 {
    min-width: 1rem;
  }
  .if.md-max-w-16 {
    max-width: 1rem;
  }
  .if.md-min-h-16 {
    min-height: 1rem;
  }
  .if.md-max-h-16 {
    max-height: 1rem;
  }
  .if.md-min-w-18 {
    min-width: 1.125rem;
  }
  .if.md-max-w-18 {
    max-width: 1.125rem;
  }
  .if.md-min-h-18 {
    min-height: 1.125rem;
  }
  .if.md-max-h-18 {
    max-height: 1.125rem;
  }
  .if.md-min-w-20 {
    min-width: 1.25rem;
  }
  .if.md-max-w-20 {
    max-width: 1.25rem;
  }
  .if.md-min-h-20 {
    min-height: 1.25rem;
  }
  .if.md-max-h-20 {
    max-height: 1.25rem;
  }
  .if.md-min-w-24 {
    min-width: 1.5rem;
  }
  .if.md-max-w-24 {
    max-width: 1.5rem;
  }
  .if.md-min-h-24 {
    min-height: 1.5rem;
  }
  .if.md-max-h-24 {
    max-height: 1.5rem;
  }
  .if.md-min-w-32 {
    min-width: 2rem;
  }
  .if.md-max-w-32 {
    max-width: 2rem;
  }
  .if.md-min-h-32 {
    min-height: 2rem;
  }
  .if.md-max-h-32 {
    max-height: 2rem;
  }
  .if.md-min-w-36 {
    min-width: 2.25rem;
  }
  .if.md-max-w-36 {
    max-width: 2.25rem;
  }
  .if.md-min-h-36 {
    min-height: 2.25rem;
  }
  .if.md-max-h-36 {
    max-height: 2.25rem;
  }
  .if.md-min-w-40 {
    min-width: 2.5rem;
  }
  .if.md-max-w-40 {
    max-width: 2.5rem;
  }
  .if.md-min-h-40 {
    min-height: 2.5rem;
  }
  .if.md-max-h-40 {
    max-height: 2.5rem;
  }
  .if.md-min-w-48 {
    min-width: 3rem;
  }
  .if.md-max-w-48 {
    max-width: 3rem;
  }
  .if.md-min-h-48 {
    min-height: 3rem;
  }
  .if.md-max-h-48 {
    max-height: 3rem;
  }
  .if.md-min-w-64 {
    min-width: 4rem;
  }
  .if.md-max-w-64 {
    max-width: 4rem;
  }
  .if.md-min-h-64 {
    min-height: 4rem;
  }
  .if.md-max-h-64 {
    max-height: 4rem;
  }
  .if.md-min-w-72 {
    min-width: 4.5rem;
  }
  .if.md-max-w-72 {
    max-width: 4.5rem;
  }
  .if.md-min-h-72 {
    min-height: 4.5rem;
  }
  .if.md-max-h-72 {
    max-height: 4.5rem;
  }
  .if.md-min-w-96 {
    min-width: 6rem;
  }
  .if.md-max-w-96 {
    max-width: 6rem;
  }
  .if.md-min-h-96 {
    min-height: 6rem;
  }
  .if.md-max-h-96 {
    max-height: 6rem;
  }
  .if.md-min-w-128 {
    min-width: 8rem;
  }
  .if.md-max-w-128 {
    max-width: 8rem;
  }
  .if.md-min-h-128 {
    min-height: 8rem;
  }
  .if.md-max-h-128 {
    max-height: 8rem;
  }
  .if.md-min-w-144 {
    min-width: 9rem;
  }
  .if.md-max-w-144 {
    max-width: 9rem;
  }
  .if.md-min-h-144 {
    min-height: 9rem;
  }
  .if.md-max-h-144 {
    max-height: 9rem;
  }
  .if.md-min-w-192 {
    min-width: 12rem;
  }
  .if.md-max-w-192 {
    max-width: 12rem;
  }
  .if.md-min-h-192 {
    min-height: 12rem;
  }
  .if.md-max-h-192 {
    max-height: 12rem;
  }
  .if.md-min-w-256 {
    min-width: 16rem;
  }
  .if.md-max-w-256 {
    max-width: 16rem;
  }
  .if.md-min-h-256 {
    min-height: 16rem;
  }
  .if.md-max-h-256 {
    max-height: 16rem;
  }
  .if.md-min-w-384 {
    min-width: 24rem;
  }
  .if.md-max-w-384 {
    max-width: 24rem;
  }
  .if.md-min-h-384 {
    min-height: 24rem;
  }
  .if.md-max-h-384 {
    max-height: 24rem;
  }
  .if.md-min-w-512 {
    min-width: 32rem;
  }
  .if.md-max-w-512 {
    max-width: 32rem;
  }
  .if.md-min-h-512 {
    min-height: 32rem;
  }
  .if.md-max-h-512 {
    max-height: 32rem;
  }
  .if.md-min-w-768 {
    min-width: 48rem;
  }
  .if.md-max-w-768 {
    max-width: 48rem;
  }
  .if.md-min-h-768 {
    min-height: 48rem;
  }
  .if.md-max-h-768 {
    max-height: 48rem;
  }
  .if.md-min-w-1024 {
    min-width: 64rem;
  }
  .if.md-max-w-1024 {
    max-width: 64rem;
  }
  .if.md-min-h-1024 {
    min-height: 64rem;
  }
  .if.md-max-h-1024 {
    max-height: 64rem;
  }
  .if.md-min-25p {
    min-width: 25%;
    min-height: 25%;
  }
  .if.md-max-25p {
    max-width: 25%;
    max-height: 25%;
  }
  .if.md-min-33p {
    min-width: 33.33%;
    min-height: 33.33%;
  }
  .if.md-max-33p {
    max-width: 33.33%;
    max-height: 33.33%;
  }
  .if.md-min-50p {
    min-width: 50%;
    min-height: 50%;
  }
  .if.md-max-50p {
    max-width: 50%;
    max-height: 50%;
  }
  .if.md-min-66p {
    min-width: 66.67%;
    min-height: 66.67%;
  }
  .if.md-max-66p {
    max-width: 66.67%;
    max-height: 66.67%;
  }
  .if.md-min-75p {
    min-width: 75%;
    min-height: 75%;
  }
  .if.md-max-75p {
    max-width: 75%;
    max-height: 75%;
  }
  .if.md-min-100p {
    min-width: 100%;
    min-height: 100%;
  }
  .if.md-max-100p {
    max-width: 100%;
    max-height: 100%;
  }
  .if.md-min-w-25p {
    min-width: 25%;
  }
  .if.md-max-w-25p {
    max-width: 25%;
  }
  .if.md-min-h-25p {
    min-height: 25%;
  }
  .if.md-max-h-25p {
    max-height: 25%;
  }
  .if.md-min-w-33p {
    min-width: 33.33%;
  }
  .if.md-max-w-33p {
    max-width: 33.33%;
  }
  .if.md-min-h-33p {
    min-height: 33.33%;
  }
  .if.md-max-h-33p {
    max-height: 33.33%;
  }
  .if.md-min-w-50p {
    min-width: 50%;
  }
  .if.md-max-w-50p {
    max-width: 50%;
  }
  .if.md-min-h-50p {
    min-height: 50%;
  }
  .if.md-max-h-50p {
    max-height: 50%;
  }
  .if.md-min-w-66p {
    min-width: 66.67%;
  }
  .if.md-max-w-66p {
    max-width: 66.67%;
  }
  .if.md-min-h-66p {
    min-height: 66.67%;
  }
  .if.md-max-h-66p {
    max-height: 66.67%;
  }
  .if.md-min-w-75p {
    min-width: 75%;
  }
  .if.md-max-w-75p {
    max-width: 75%;
  }
  .if.md-min-h-75p {
    min-height: 75%;
  }
  .if.md-max-h-75p {
    max-height: 75%;
  }
  .if.md-min-w-100p {
    min-width: 100%;
  }
  .if.md-max-w-100p {
    max-width: 100%;
  }
  .if.md-min-h-100p {
    min-height: 100%;
  }
  .if.md-max-h-100p {
    max-height: 100%;
  }
  .if.md-row-gap-2 {
    row-gap: 0.125rem;
  }
  .if.md-col-gap-2 {
    column-gap: 0.125rem;
  }
  .if.md-row-gap-4 {
    row-gap: 0.25rem;
  }
  .if.md-col-gap-4 {
    column-gap: 0.25rem;
  }
  .if.md-row-gap-8 {
    row-gap: 0.5rem;
  }
  .if.md-col-gap-8 {
    column-gap: 0.5rem;
  }
  .if.md-row-gap-12 {
    row-gap: 0.75rem;
  }
  .if.md-col-gap-12 {
    column-gap: 0.75rem;
  }
  .if.md-row-gap-16 {
    row-gap: 1rem;
  }
  .if.md-col-gap-16 {
    column-gap: 1rem;
  }
  .if.md-row-gap-20 {
    row-gap: 1.25rem;
  }
  .if.md-col-gap-20 {
    column-gap: 1.25rem;
  }
  .if.md-row-gap-24 {
    row-gap: 1.5rem;
  }
  .if.md-col-gap-24 {
    column-gap: 1.5rem;
  }
  .if.md-row-gap-32 {
    row-gap: 2rem;
  }
  .if.md-col-gap-32 {
    column-gap: 2rem;
  }
  .if.md-row-gap-40 {
    row-gap: 2.5rem;
  }
  .if.md-col-gap-40 {
    column-gap: 2.5rem;
  }
  .if.md-row-gap-48 {
    row-gap: 3rem;
  }
  .if.md-col-gap-48 {
    column-gap: 3rem;
  }
  .if.md-row-gap-64 {
    row-gap: 4rem;
  }
  .if.md-col-gap-64 {
    column-gap: 4rem;
  }
  .if.md-row-gap-88 {
    row-gap: 5.5rem;
  }
  .if.md-col-gap-88 {
    column-gap: 5.5rem;
  }
  .if.md-row-gap-120 {
    row-gap: 7.5rem;
  }
  .if.md-col-gap-120 {
    column-gap: 7.5rem;
  }
  .if.md-gap-2 {
    gap: 0.125rem;
  }
  .if.md-gap-4 {
    gap: 0.25rem;
  }
  .if.md-gap-8 {
    gap: 0.5rem;
  }
  .if.md-gap-12 {
    gap: 0.75rem;
  }
  .if.md-gap-16 {
    gap: 1rem;
  }
  .if.md-gap-20 {
    gap: 1.25rem;
  }
  .if.md-gap-24 {
    gap: 1.5rem;
  }
  .if.md-gap-32 {
    gap: 2rem;
  }
  .if.md-gap-40 {
    gap: 2.5rem;
  }
  .if.md-gap-48 {
    gap: 3rem;
  }
  .if.md-gap-64 {
    gap: 4rem;
  }
  .if.md-gap-88 {
    gap: 5.5rem;
  }
  .if.md-gap-120 {
    gap: 7.5rem;
  }
}
@media screen and (min-width: 77.5rem) {
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content */
  .if.lg-justify-content-center {
    justify-content: center;
  }
  .if.lg-justify-content-start {
    justify-content: start;
  }
  .if.lg-justify-content-end {
    justify-content: end;
  }
  .if.lg-justify-content-left {
    justify-content: left;
  }
  .if.lg-justify-content-right {
    justify-content: right;
  }
  .if.lg-justify-content-space-between {
    justify-content: space-between;
  }
  .if.lg-justify-content-space-around {
    justify-content: space-around;
  }
  .if.lg-justify-content-space-evenly {
    justify-content: space-evenly;
  }
  .if.lg-justify-content-stretch {
    justify-content: stretch;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-items */
  .if.lg-justify-items-normal {
    justify-items: normal;
  }
  .if.lg-justify-items-stretch {
    justify-items: stretch;
  }
  .if.lg-justify-items-center {
    justify-items: center;
  }
  .if.lg-justify-items-start {
    justify-items: start;
  }
  .if.lg-justify-items-end {
    justify-items: end;
  }
  .if.lg-justify-items-left {
    justify-items: left;
  }
  .if.lg-justify-items-right {
    justify-items: right;
  }
  .if.lg-justify-items-baseline {
    justify-items: baseline;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/justify-self */
  .if.lg-justify-self-auto {
    justify-self: auto;
  }
  .if.lg-justify-self-normal {
    justify-self: normal;
  }
  .if.lg-justify-self-stretch {
    justify-self: stretch;
  }
  .if.lg-justify-self-center {
    justify-self: center;
  }
  .if.lg-justify-self-start {
    justify-self: start;
  }
  .if.lg-justify-self-end {
    justify-self: end;
  }
  .if.lg-justify-self-left {
    justify-self: left;
  }
  .if.lg-justify-self-right {
    justify-self: right;
  }
  .if.lg-justify-self-baseline {
    justify-self: baseline;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/align-content */
  .if.lg-align-content-center {
    align-content: center;
  }
  .if.lg-align-content-start {
    align-content: start;
  }
  .if.lg-align-content-end {
    align-content: end;
  }
  .if.lg-align-content-baseline {
    align-content: baseline;
  }
  .if.lg-align-content-space-between {
    align-content: space-between;
  }
  .if.lg-align-content-space-around {
    align-content: space-around;
  }
  .if.lg-align-content-space-evenly {
    align-content: space-evenly;
  }
  .if.lg-align-content-stretch {
    align-content: stretch;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/align-items */
  .if.lg-align-items-center {
    align-items: center;
  }
  .if.lg-align-items-start {
    align-items: start;
  }
  .if.lg-align-items-end {
    align-items: end;
  }
  .if.lg-align-items-baseline {
    align-items: baseline;
  }
  .if.lg-align-items-stretch {
    align-items: stretch;
  }
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/align-self */
  .if.lg-align-self-auto {
    align-self: auto;
  }
  .if.lg-align-self-center {
    align-self: center;
  }
  .if.lg-align-self-start {
    align-self: start;
  }
  .if.lg-align-self-end {
    align-self: end;
  }
  .if.lg-align-self-baseline {
    align-self: baseline;
  }
  .if.lg-align-self-stretch {
    align-self: stretch;
  }
  .if.lg-grid-container {
    display: grid;
  }
  .if.lg-flex {
    display: flex;
  }
  .if.lg-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .if.lg-flex-row {
    display: flex;
    flex-direction: row;
  }
  .if.lg-flex-column {
    display: flex;
    flex-direction: column;
  }
  .if.lg-flex-wrap {
    flex-wrap: wrap;
  }
  .if.lg-flex-no-wrap {
    flex-wrap: nowrap;
  }
  .if.lg-flex-grow {
    flex-grow: 1;
  }
  .if.lg-flex-grow-0 {
    flex-grow: 0;
  }
  .if.lg-flex-shrink {
    flex-shrink: 1;
  }
  .if.lg-flex-shrink-0 {
    flex-shrink: 0;
  }
  .if.lg-position-relative {
    position: relative;
  }
  .if.lg-position-absolute {
    position: absolute;
  }
  .if.lg-display-none {
    display: none;
  }
  .if.lg-display-block {
    display: block;
  }
  .if.lg-overflow-hidden {
    overflow: hidden;
  }
  .if.lg-overflow-scroll {
    overflow: scroll;
  }
  .if.lg-overflow-y-scroll {
    overflow-y: scroll;
  }
  .if.lg-overflow-x-scroll {
    overflow-x: scroll;
  }
  .if.lg-m-2 {
    margin: 0.125rem;
  }
  .if.lg-my-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .if.lg-mx-2 {
    margin-right: 0.125rem;
    margin-left: 0.125rem;
  }
  .if.lg-m-4 {
    margin: 0.25rem;
  }
  .if.lg-my-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .if.lg-mx-4 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .if.lg-m-8 {
    margin: 0.5rem;
  }
  .if.lg-my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .if.lg-mx-8 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .if.lg-m-12 {
    margin: 0.75rem;
  }
  .if.lg-my-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .if.lg-mx-12 {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .if.lg-m-16 {
    margin: 1rem;
  }
  .if.lg-my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .if.lg-mx-16 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .if.lg-m-20 {
    margin: 1.25rem;
  }
  .if.lg-my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .if.lg-mx-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .if.lg-m-24 {
    margin: 1.5rem;
  }
  .if.lg-my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .if.lg-mx-24 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .if.lg-m-32 {
    margin: 2rem;
  }
  .if.lg-my-32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .if.lg-mx-32 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .if.lg-m-40 {
    margin: 2.5rem;
  }
  .if.lg-my-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .if.lg-mx-40 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .if.lg-m-48 {
    margin: 3rem;
  }
  .if.lg-my-48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .if.lg-mx-48 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .if.lg-m-64 {
    margin: 4rem;
  }
  .if.lg-my-64 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .if.lg-mx-64 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .if.lg-m-88 {
    margin: 5.5rem;
  }
  .if.lg-my-88 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
  }
  .if.lg-mx-88 {
    margin-right: 5.5rem;
    margin-left: 5.5rem;
  }
  .if.lg-m-120 {
    margin: 7.5rem;
  }
  .if.lg-my-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .if.lg-mx-120 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .if.lg-p-2 {
    padding: 0.125rem;
  }
  .if.lg-py-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .if.lg-px-2 {
    padding-right: 0.125rem;
    padding-left: 0.125rem;
  }
  .if.lg-p-4 {
    padding: 0.25rem;
  }
  .if.lg-py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .if.lg-px-4 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .if.lg-p-8 {
    padding: 0.5rem;
  }
  .if.lg-py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .if.lg-px-8 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .if.lg-p-12 {
    padding: 0.75rem;
  }
  .if.lg-py-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .if.lg-px-12 {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .if.lg-p-16 {
    padding: 1rem;
  }
  .if.lg-py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .if.lg-px-16 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .if.lg-p-20 {
    padding: 1.25rem;
  }
  .if.lg-py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .if.lg-px-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .if.lg-p-24 {
    padding: 1.5rem;
  }
  .if.lg-py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .if.lg-px-24 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .if.lg-p-32 {
    padding: 2rem;
  }
  .if.lg-py-32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .if.lg-px-32 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .if.lg-p-40 {
    padding: 2.5rem;
  }
  .if.lg-py-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .if.lg-px-40 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .if.lg-p-48 {
    padding: 3rem;
  }
  .if.lg-py-48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .if.lg-px-48 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .if.lg-p-64 {
    padding: 4rem;
  }
  .if.lg-py-64 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .if.lg-px-64 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .if.lg-p-88 {
    padding: 5.5rem;
  }
  .if.lg-py-88 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .if.lg-px-88 {
    padding-right: 5.5rem;
    padding-left: 5.5rem;
  }
  .if.lg-p-120 {
    padding: 7.5rem;
  }
  .if.lg-py-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .if.lg-px-120 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .if.lg-mt-2 {
    margin-top: 0.125rem;
  }
  .if.lg-mb-2 {
    margin-bottom: 0.125rem;
  }
  .if.lg-mr-2 {
    margin-right: 0.125rem;
  }
  .if.lg-ml-2 {
    margin-left: 0.125rem;
  }
  .if.lg-mt-4 {
    margin-top: 0.25rem;
  }
  .if.lg-mb-4 {
    margin-bottom: 0.25rem;
  }
  .if.lg-mr-4 {
    margin-right: 0.25rem;
  }
  .if.lg-ml-4 {
    margin-left: 0.25rem;
  }
  .if.lg-mt-8 {
    margin-top: 0.5rem;
  }
  .if.lg-mb-8 {
    margin-bottom: 0.5rem;
  }
  .if.lg-mr-8 {
    margin-right: 0.5rem;
  }
  .if.lg-ml-8 {
    margin-left: 0.5rem;
  }
  .if.lg-mt-12 {
    margin-top: 0.75rem;
  }
  .if.lg-mb-12 {
    margin-bottom: 0.75rem;
  }
  .if.lg-mr-12 {
    margin-right: 0.75rem;
  }
  .if.lg-ml-12 {
    margin-left: 0.75rem;
  }
  .if.lg-mt-16 {
    margin-top: 1rem;
  }
  .if.lg-mb-16 {
    margin-bottom: 1rem;
  }
  .if.lg-mr-16 {
    margin-right: 1rem;
  }
  .if.lg-ml-16 {
    margin-left: 1rem;
  }
  .if.lg-mt-20 {
    margin-top: 1.25rem;
  }
  .if.lg-mb-20 {
    margin-bottom: 1.25rem;
  }
  .if.lg-mr-20 {
    margin-right: 1.25rem;
  }
  .if.lg-ml-20 {
    margin-left: 1.25rem;
  }
  .if.lg-mt-24 {
    margin-top: 1.5rem;
  }
  .if.lg-mb-24 {
    margin-bottom: 1.5rem;
  }
  .if.lg-mr-24 {
    margin-right: 1.5rem;
  }
  .if.lg-ml-24 {
    margin-left: 1.5rem;
  }
  .if.lg-mt-32 {
    margin-top: 2rem;
  }
  .if.lg-mb-32 {
    margin-bottom: 2rem;
  }
  .if.lg-mr-32 {
    margin-right: 2rem;
  }
  .if.lg-ml-32 {
    margin-left: 2rem;
  }
  .if.lg-mt-40 {
    margin-top: 2.5rem;
  }
  .if.lg-mb-40 {
    margin-bottom: 2.5rem;
  }
  .if.lg-mr-40 {
    margin-right: 2.5rem;
  }
  .if.lg-ml-40 {
    margin-left: 2.5rem;
  }
  .if.lg-mt-48 {
    margin-top: 3rem;
  }
  .if.lg-mb-48 {
    margin-bottom: 3rem;
  }
  .if.lg-mr-48 {
    margin-right: 3rem;
  }
  .if.lg-ml-48 {
    margin-left: 3rem;
  }
  .if.lg-mt-64 {
    margin-top: 4rem;
  }
  .if.lg-mb-64 {
    margin-bottom: 4rem;
  }
  .if.lg-mr-64 {
    margin-right: 4rem;
  }
  .if.lg-ml-64 {
    margin-left: 4rem;
  }
  .if.lg-mt-88 {
    margin-top: 5.5rem;
  }
  .if.lg-mb-88 {
    margin-bottom: 5.5rem;
  }
  .if.lg-mr-88 {
    margin-right: 5.5rem;
  }
  .if.lg-ml-88 {
    margin-left: 5.5rem;
  }
  .if.lg-mt-120 {
    margin-top: 7.5rem;
  }
  .if.lg-mb-120 {
    margin-bottom: 7.5rem;
  }
  .if.lg-mr-120 {
    margin-right: 7.5rem;
  }
  .if.lg-ml-120 {
    margin-left: 7.5rem;
  }
  .if.lg-pt-2 {
    padding-top: 0.125rem;
  }
  .if.lg-pb-2 {
    padding-bottom: 0.125rem;
  }
  .if.lg-pr-2 {
    padding-right: 0.125rem;
  }
  .if.lg-pl-2 {
    padding-left: 0.125rem;
  }
  .if.lg-pt-4 {
    padding-top: 0.25rem;
  }
  .if.lg-pb-4 {
    padding-bottom: 0.25rem;
  }
  .if.lg-pr-4 {
    padding-right: 0.25rem;
  }
  .if.lg-pl-4 {
    padding-left: 0.25rem;
  }
  .if.lg-pt-8 {
    padding-top: 0.5rem;
  }
  .if.lg-pb-8 {
    padding-bottom: 0.5rem;
  }
  .if.lg-pr-8 {
    padding-right: 0.5rem;
  }
  .if.lg-pl-8 {
    padding-left: 0.5rem;
  }
  .if.lg-pt-12 {
    padding-top: 0.75rem;
  }
  .if.lg-pb-12 {
    padding-bottom: 0.75rem;
  }
  .if.lg-pr-12 {
    padding-right: 0.75rem;
  }
  .if.lg-pl-12 {
    padding-left: 0.75rem;
  }
  .if.lg-pt-16 {
    padding-top: 1rem;
  }
  .if.lg-pb-16 {
    padding-bottom: 1rem;
  }
  .if.lg-pr-16 {
    padding-right: 1rem;
  }
  .if.lg-pl-16 {
    padding-left: 1rem;
  }
  .if.lg-pt-20 {
    padding-top: 1.25rem;
  }
  .if.lg-pb-20 {
    padding-bottom: 1.25rem;
  }
  .if.lg-pr-20 {
    padding-right: 1.25rem;
  }
  .if.lg-pl-20 {
    padding-left: 1.25rem;
  }
  .if.lg-pt-24 {
    padding-top: 1.5rem;
  }
  .if.lg-pb-24 {
    padding-bottom: 1.5rem;
  }
  .if.lg-pr-24 {
    padding-right: 1.5rem;
  }
  .if.lg-pl-24 {
    padding-left: 1.5rem;
  }
  .if.lg-pt-32 {
    padding-top: 2rem;
  }
  .if.lg-pb-32 {
    padding-bottom: 2rem;
  }
  .if.lg-pr-32 {
    padding-right: 2rem;
  }
  .if.lg-pl-32 {
    padding-left: 2rem;
  }
  .if.lg-pt-40 {
    padding-top: 2.5rem;
  }
  .if.lg-pb-40 {
    padding-bottom: 2.5rem;
  }
  .if.lg-pr-40 {
    padding-right: 2.5rem;
  }
  .if.lg-pl-40 {
    padding-left: 2.5rem;
  }
  .if.lg-pt-48 {
    padding-top: 3rem;
  }
  .if.lg-pb-48 {
    padding-bottom: 3rem;
  }
  .if.lg-pr-48 {
    padding-right: 3rem;
  }
  .if.lg-pl-48 {
    padding-left: 3rem;
  }
  .if.lg-pt-64 {
    padding-top: 4rem;
  }
  .if.lg-pb-64 {
    padding-bottom: 4rem;
  }
  .if.lg-pr-64 {
    padding-right: 4rem;
  }
  .if.lg-pl-64 {
    padding-left: 4rem;
  }
  .if.lg-pt-88 {
    padding-top: 5.5rem;
  }
  .if.lg-pb-88 {
    padding-bottom: 5.5rem;
  }
  .if.lg-pr-88 {
    padding-right: 5.5rem;
  }
  .if.lg-pl-88 {
    padding-left: 5.5rem;
  }
  .if.lg-pt-120 {
    padding-top: 7.5rem;
  }
  .if.lg-pb-120 {
    padding-bottom: 7.5rem;
  }
  .if.lg-pr-120 {
    padding-right: 7.5rem;
  }
  .if.lg-pl-120 {
    padding-left: 7.5rem;
  }
  .if.lg-no-padding {
    padding: 0;
  }
  .if.lg-no-margin {
    margin: 0;
  }
  .if.lg-mx-auto, .if.lg-mh-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .if.lg-size-8 {
    width: 0.5rem;
    height: 0.5rem;
  }
  .if.lg-size-10 {
    width: 0.625rem;
    height: 0.625rem;
  }
  .if.lg-size-12 {
    width: 0.75rem;
    height: 0.75rem;
  }
  .if.lg-size-16 {
    width: 1rem;
    height: 1rem;
  }
  .if.lg-size-18 {
    width: 1.125rem;
    height: 1.125rem;
  }
  .if.lg-size-20 {
    width: 1.25rem;
    height: 1.25rem;
  }
  .if.lg-size-24 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .if.lg-size-32 {
    width: 2rem;
    height: 2rem;
  }
  .if.lg-size-36 {
    width: 2.25rem;
    height: 2.25rem;
  }
  .if.lg-size-40 {
    width: 2.5rem;
    height: 2.5rem;
  }
  .if.lg-size-48 {
    width: 3rem;
    height: 3rem;
  }
  .if.lg-size-64 {
    width: 4rem;
    height: 4rem;
  }
  .if.lg-size-72 {
    width: 4.5rem;
    height: 4.5rem;
  }
  .if.lg-size-96 {
    width: 6rem;
    height: 6rem;
  }
  .if.lg-size-128 {
    width: 8rem;
    height: 8rem;
  }
  .if.lg-size-144 {
    width: 9rem;
    height: 9rem;
  }
  .if.lg-size-192 {
    width: 12rem;
    height: 12rem;
  }
  .if.lg-size-256 {
    width: 16rem;
    height: 16rem;
  }
  .if.lg-size-384 {
    width: 24rem;
    height: 24rem;
  }
  .if.lg-size-512 {
    width: 32rem;
    height: 32rem;
  }
  .if.lg-size-768 {
    width: 48rem;
    height: 48rem;
  }
  .if.lg-size-1024 {
    width: 64rem;
    height: 64rem;
  }
  .if.lg-size-w-8 {
    width: 0.5rem;
  }
  .if.lg-size-h-8 {
    height: 0.5rem;
  }
  .if.lg-size-w-10 {
    width: 0.625rem;
  }
  .if.lg-size-h-10 {
    height: 0.625rem;
  }
  .if.lg-size-w-12 {
    width: 0.75rem;
  }
  .if.lg-size-h-12 {
    height: 0.75rem;
  }
  .if.lg-size-w-16 {
    width: 1rem;
  }
  .if.lg-size-h-16 {
    height: 1rem;
  }
  .if.lg-size-w-18 {
    width: 1.125rem;
  }
  .if.lg-size-h-18 {
    height: 1.125rem;
  }
  .if.lg-size-w-20 {
    width: 1.25rem;
  }
  .if.lg-size-h-20 {
    height: 1.25rem;
  }
  .if.lg-size-w-24 {
    width: 1.5rem;
  }
  .if.lg-size-h-24 {
    height: 1.5rem;
  }
  .if.lg-size-w-32 {
    width: 2rem;
  }
  .if.lg-size-h-32 {
    height: 2rem;
  }
  .if.lg-size-w-36 {
    width: 2.25rem;
  }
  .if.lg-size-h-36 {
    height: 2.25rem;
  }
  .if.lg-size-w-40 {
    width: 2.5rem;
  }
  .if.lg-size-h-40 {
    height: 2.5rem;
  }
  .if.lg-size-w-48 {
    width: 3rem;
  }
  .if.lg-size-h-48 {
    height: 3rem;
  }
  .if.lg-size-w-64 {
    width: 4rem;
  }
  .if.lg-size-h-64 {
    height: 4rem;
  }
  .if.lg-size-w-72 {
    width: 4.5rem;
  }
  .if.lg-size-h-72 {
    height: 4.5rem;
  }
  .if.lg-size-w-96 {
    width: 6rem;
  }
  .if.lg-size-h-96 {
    height: 6rem;
  }
  .if.lg-size-w-128 {
    width: 8rem;
  }
  .if.lg-size-h-128 {
    height: 8rem;
  }
  .if.lg-size-w-144 {
    width: 9rem;
  }
  .if.lg-size-h-144 {
    height: 9rem;
  }
  .if.lg-size-w-192 {
    width: 12rem;
  }
  .if.lg-size-h-192 {
    height: 12rem;
  }
  .if.lg-size-w-256 {
    width: 16rem;
  }
  .if.lg-size-h-256 {
    height: 16rem;
  }
  .if.lg-size-w-384 {
    width: 24rem;
  }
  .if.lg-size-h-384 {
    height: 24rem;
  }
  .if.lg-size-w-512 {
    width: 32rem;
  }
  .if.lg-size-h-512 {
    height: 32rem;
  }
  .if.lg-size-w-768 {
    width: 48rem;
  }
  .if.lg-size-h-768 {
    height: 48rem;
  }
  .if.lg-size-w-1024 {
    width: 64rem;
  }
  .if.lg-size-h-1024 {
    height: 64rem;
  }
  .if.lg-size-25p {
    width: 25%;
    height: 25%;
  }
  .if.lg-size-33p {
    width: 33.33%;
    height: 33.33%;
  }
  .if.lg-size-50p {
    width: 50%;
    height: 50%;
  }
  .if.lg-size-66p {
    width: 66.67%;
    height: 66.67%;
  }
  .if.lg-size-75p {
    width: 75%;
    height: 75%;
  }
  .if.lg-size-100p {
    width: 100%;
    height: 100%;
  }
  .if.lg-size-w-25p {
    width: 25%;
  }
  .if.lg-size-h-25p {
    height: 25%;
  }
  .if.lg-size-w-33p {
    width: 33.33%;
  }
  .if.lg-size-h-33p {
    height: 33.33%;
  }
  .if.lg-size-w-50p {
    width: 50%;
  }
  .if.lg-size-h-50p {
    height: 50%;
  }
  .if.lg-size-w-66p {
    width: 66.67%;
  }
  .if.lg-size-h-66p {
    height: 66.67%;
  }
  .if.lg-size-w-75p {
    width: 75%;
  }
  .if.lg-size-h-75p {
    height: 75%;
  }
  .if.lg-size-w-100p {
    width: 100%;
  }
  .if.lg-size-h-100p {
    height: 100%;
  }
  .if.lg-min-8 {
    min-width: 0.5rem;
    min-height: 0.5rem;
  }
  .if.lg-max-8 {
    max-width: 0.5rem;
    max-height: 0.5rem;
  }
  .if.lg-min-10 {
    min-width: 0.625rem;
    min-height: 0.625rem;
  }
  .if.lg-max-10 {
    max-width: 0.625rem;
    max-height: 0.625rem;
  }
  .if.lg-min-12 {
    min-width: 0.75rem;
    min-height: 0.75rem;
  }
  .if.lg-max-12 {
    max-width: 0.75rem;
    max-height: 0.75rem;
  }
  .if.lg-min-16 {
    min-width: 1rem;
    min-height: 1rem;
  }
  .if.lg-max-16 {
    max-width: 1rem;
    max-height: 1rem;
  }
  .if.lg-min-18 {
    min-width: 1.125rem;
    min-height: 1.125rem;
  }
  .if.lg-max-18 {
    max-width: 1.125rem;
    max-height: 1.125rem;
  }
  .if.lg-min-20 {
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
  .if.lg-max-20 {
    max-width: 1.25rem;
    max-height: 1.25rem;
  }
  .if.lg-min-24 {
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
  .if.lg-max-24 {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }
  .if.lg-min-32 {
    min-width: 2rem;
    min-height: 2rem;
  }
  .if.lg-max-32 {
    max-width: 2rem;
    max-height: 2rem;
  }
  .if.lg-min-36 {
    min-width: 2.25rem;
    min-height: 2.25rem;
  }
  .if.lg-max-36 {
    max-width: 2.25rem;
    max-height: 2.25rem;
  }
  .if.lg-min-40 {
    min-width: 2.5rem;
    min-height: 2.5rem;
  }
  .if.lg-max-40 {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }
  .if.lg-min-48 {
    min-width: 3rem;
    min-height: 3rem;
  }
  .if.lg-max-48 {
    max-width: 3rem;
    max-height: 3rem;
  }
  .if.lg-min-64 {
    min-width: 4rem;
    min-height: 4rem;
  }
  .if.lg-max-64 {
    max-width: 4rem;
    max-height: 4rem;
  }
  .if.lg-min-72 {
    min-width: 4.5rem;
    min-height: 4.5rem;
  }
  .if.lg-max-72 {
    max-width: 4.5rem;
    max-height: 4.5rem;
  }
  .if.lg-min-96 {
    min-width: 6rem;
    min-height: 6rem;
  }
  .if.lg-max-96 {
    max-width: 6rem;
    max-height: 6rem;
  }
  .if.lg-min-128 {
    min-width: 8rem;
    min-height: 8rem;
  }
  .if.lg-max-128 {
    max-width: 8rem;
    max-height: 8rem;
  }
  .if.lg-min-144 {
    min-width: 9rem;
    min-height: 9rem;
  }
  .if.lg-max-144 {
    max-width: 9rem;
    max-height: 9rem;
  }
  .if.lg-min-192 {
    min-width: 12rem;
    min-height: 12rem;
  }
  .if.lg-max-192 {
    max-width: 12rem;
    max-height: 12rem;
  }
  .if.lg-min-256 {
    min-width: 16rem;
    min-height: 16rem;
  }
  .if.lg-max-256 {
    max-width: 16rem;
    max-height: 16rem;
  }
  .if.lg-min-384 {
    min-width: 24rem;
    min-height: 24rem;
  }
  .if.lg-max-384 {
    max-width: 24rem;
    max-height: 24rem;
  }
  .if.lg-min-512 {
    min-width: 32rem;
    min-height: 32rem;
  }
  .if.lg-max-512 {
    max-width: 32rem;
    max-height: 32rem;
  }
  .if.lg-min-768 {
    min-width: 48rem;
    min-height: 48rem;
  }
  .if.lg-max-768 {
    max-width: 48rem;
    max-height: 48rem;
  }
  .if.lg-min-1024 {
    min-width: 64rem;
    min-height: 64rem;
  }
  .if.lg-max-1024 {
    max-width: 64rem;
    max-height: 64rem;
  }
  .if.lg-min-w-8 {
    min-width: 0.5rem;
  }
  .if.lg-max-w-8 {
    max-width: 0.5rem;
  }
  .if.lg-min-h-8 {
    min-height: 0.5rem;
  }
  .if.lg-max-h-8 {
    max-height: 0.5rem;
  }
  .if.lg-min-w-10 {
    min-width: 0.625rem;
  }
  .if.lg-max-w-10 {
    max-width: 0.625rem;
  }
  .if.lg-min-h-10 {
    min-height: 0.625rem;
  }
  .if.lg-max-h-10 {
    max-height: 0.625rem;
  }
  .if.lg-min-w-12 {
    min-width: 0.75rem;
  }
  .if.lg-max-w-12 {
    max-width: 0.75rem;
  }
  .if.lg-min-h-12 {
    min-height: 0.75rem;
  }
  .if.lg-max-h-12 {
    max-height: 0.75rem;
  }
  .if.lg-min-w-16 {
    min-width: 1rem;
  }
  .if.lg-max-w-16 {
    max-width: 1rem;
  }
  .if.lg-min-h-16 {
    min-height: 1rem;
  }
  .if.lg-max-h-16 {
    max-height: 1rem;
  }
  .if.lg-min-w-18 {
    min-width: 1.125rem;
  }
  .if.lg-max-w-18 {
    max-width: 1.125rem;
  }
  .if.lg-min-h-18 {
    min-height: 1.125rem;
  }
  .if.lg-max-h-18 {
    max-height: 1.125rem;
  }
  .if.lg-min-w-20 {
    min-width: 1.25rem;
  }
  .if.lg-max-w-20 {
    max-width: 1.25rem;
  }
  .if.lg-min-h-20 {
    min-height: 1.25rem;
  }
  .if.lg-max-h-20 {
    max-height: 1.25rem;
  }
  .if.lg-min-w-24 {
    min-width: 1.5rem;
  }
  .if.lg-max-w-24 {
    max-width: 1.5rem;
  }
  .if.lg-min-h-24 {
    min-height: 1.5rem;
  }
  .if.lg-max-h-24 {
    max-height: 1.5rem;
  }
  .if.lg-min-w-32 {
    min-width: 2rem;
  }
  .if.lg-max-w-32 {
    max-width: 2rem;
  }
  .if.lg-min-h-32 {
    min-height: 2rem;
  }
  .if.lg-max-h-32 {
    max-height: 2rem;
  }
  .if.lg-min-w-36 {
    min-width: 2.25rem;
  }
  .if.lg-max-w-36 {
    max-width: 2.25rem;
  }
  .if.lg-min-h-36 {
    min-height: 2.25rem;
  }
  .if.lg-max-h-36 {
    max-height: 2.25rem;
  }
  .if.lg-min-w-40 {
    min-width: 2.5rem;
  }
  .if.lg-max-w-40 {
    max-width: 2.5rem;
  }
  .if.lg-min-h-40 {
    min-height: 2.5rem;
  }
  .if.lg-max-h-40 {
    max-height: 2.5rem;
  }
  .if.lg-min-w-48 {
    min-width: 3rem;
  }
  .if.lg-max-w-48 {
    max-width: 3rem;
  }
  .if.lg-min-h-48 {
    min-height: 3rem;
  }
  .if.lg-max-h-48 {
    max-height: 3rem;
  }
  .if.lg-min-w-64 {
    min-width: 4rem;
  }
  .if.lg-max-w-64 {
    max-width: 4rem;
  }
  .if.lg-min-h-64 {
    min-height: 4rem;
  }
  .if.lg-max-h-64 {
    max-height: 4rem;
  }
  .if.lg-min-w-72 {
    min-width: 4.5rem;
  }
  .if.lg-max-w-72 {
    max-width: 4.5rem;
  }
  .if.lg-min-h-72 {
    min-height: 4.5rem;
  }
  .if.lg-max-h-72 {
    max-height: 4.5rem;
  }
  .if.lg-min-w-96 {
    min-width: 6rem;
  }
  .if.lg-max-w-96 {
    max-width: 6rem;
  }
  .if.lg-min-h-96 {
    min-height: 6rem;
  }
  .if.lg-max-h-96 {
    max-height: 6rem;
  }
  .if.lg-min-w-128 {
    min-width: 8rem;
  }
  .if.lg-max-w-128 {
    max-width: 8rem;
  }
  .if.lg-min-h-128 {
    min-height: 8rem;
  }
  .if.lg-max-h-128 {
    max-height: 8rem;
  }
  .if.lg-min-w-144 {
    min-width: 9rem;
  }
  .if.lg-max-w-144 {
    max-width: 9rem;
  }
  .if.lg-min-h-144 {
    min-height: 9rem;
  }
  .if.lg-max-h-144 {
    max-height: 9rem;
  }
  .if.lg-min-w-192 {
    min-width: 12rem;
  }
  .if.lg-max-w-192 {
    max-width: 12rem;
  }
  .if.lg-min-h-192 {
    min-height: 12rem;
  }
  .if.lg-max-h-192 {
    max-height: 12rem;
  }
  .if.lg-min-w-256 {
    min-width: 16rem;
  }
  .if.lg-max-w-256 {
    max-width: 16rem;
  }
  .if.lg-min-h-256 {
    min-height: 16rem;
  }
  .if.lg-max-h-256 {
    max-height: 16rem;
  }
  .if.lg-min-w-384 {
    min-width: 24rem;
  }
  .if.lg-max-w-384 {
    max-width: 24rem;
  }
  .if.lg-min-h-384 {
    min-height: 24rem;
  }
  .if.lg-max-h-384 {
    max-height: 24rem;
  }
  .if.lg-min-w-512 {
    min-width: 32rem;
  }
  .if.lg-max-w-512 {
    max-width: 32rem;
  }
  .if.lg-min-h-512 {
    min-height: 32rem;
  }
  .if.lg-max-h-512 {
    max-height: 32rem;
  }
  .if.lg-min-w-768 {
    min-width: 48rem;
  }
  .if.lg-max-w-768 {
    max-width: 48rem;
  }
  .if.lg-min-h-768 {
    min-height: 48rem;
  }
  .if.lg-max-h-768 {
    max-height: 48rem;
  }
  .if.lg-min-w-1024 {
    min-width: 64rem;
  }
  .if.lg-max-w-1024 {
    max-width: 64rem;
  }
  .if.lg-min-h-1024 {
    min-height: 64rem;
  }
  .if.lg-max-h-1024 {
    max-height: 64rem;
  }
  .if.lg-min-25p {
    min-width: 25%;
    min-height: 25%;
  }
  .if.lg-max-25p {
    max-width: 25%;
    max-height: 25%;
  }
  .if.lg-min-33p {
    min-width: 33.33%;
    min-height: 33.33%;
  }
  .if.lg-max-33p {
    max-width: 33.33%;
    max-height: 33.33%;
  }
  .if.lg-min-50p {
    min-width: 50%;
    min-height: 50%;
  }
  .if.lg-max-50p {
    max-width: 50%;
    max-height: 50%;
  }
  .if.lg-min-66p {
    min-width: 66.67%;
    min-height: 66.67%;
  }
  .if.lg-max-66p {
    max-width: 66.67%;
    max-height: 66.67%;
  }
  .if.lg-min-75p {
    min-width: 75%;
    min-height: 75%;
  }
  .if.lg-max-75p {
    max-width: 75%;
    max-height: 75%;
  }
  .if.lg-min-100p {
    min-width: 100%;
    min-height: 100%;
  }
  .if.lg-max-100p {
    max-width: 100%;
    max-height: 100%;
  }
  .if.lg-min-w-25p {
    min-width: 25%;
  }
  .if.lg-max-w-25p {
    max-width: 25%;
  }
  .if.lg-min-h-25p {
    min-height: 25%;
  }
  .if.lg-max-h-25p {
    max-height: 25%;
  }
  .if.lg-min-w-33p {
    min-width: 33.33%;
  }
  .if.lg-max-w-33p {
    max-width: 33.33%;
  }
  .if.lg-min-h-33p {
    min-height: 33.33%;
  }
  .if.lg-max-h-33p {
    max-height: 33.33%;
  }
  .if.lg-min-w-50p {
    min-width: 50%;
  }
  .if.lg-max-w-50p {
    max-width: 50%;
  }
  .if.lg-min-h-50p {
    min-height: 50%;
  }
  .if.lg-max-h-50p {
    max-height: 50%;
  }
  .if.lg-min-w-66p {
    min-width: 66.67%;
  }
  .if.lg-max-w-66p {
    max-width: 66.67%;
  }
  .if.lg-min-h-66p {
    min-height: 66.67%;
  }
  .if.lg-max-h-66p {
    max-height: 66.67%;
  }
  .if.lg-min-w-75p {
    min-width: 75%;
  }
  .if.lg-max-w-75p {
    max-width: 75%;
  }
  .if.lg-min-h-75p {
    min-height: 75%;
  }
  .if.lg-max-h-75p {
    max-height: 75%;
  }
  .if.lg-min-w-100p {
    min-width: 100%;
  }
  .if.lg-max-w-100p {
    max-width: 100%;
  }
  .if.lg-min-h-100p {
    min-height: 100%;
  }
  .if.lg-max-h-100p {
    max-height: 100%;
  }
  .if.lg-row-gap-2 {
    row-gap: 0.125rem;
  }
  .if.lg-col-gap-2 {
    column-gap: 0.125rem;
  }
  .if.lg-row-gap-4 {
    row-gap: 0.25rem;
  }
  .if.lg-col-gap-4 {
    column-gap: 0.25rem;
  }
  .if.lg-row-gap-8 {
    row-gap: 0.5rem;
  }
  .if.lg-col-gap-8 {
    column-gap: 0.5rem;
  }
  .if.lg-row-gap-12 {
    row-gap: 0.75rem;
  }
  .if.lg-col-gap-12 {
    column-gap: 0.75rem;
  }
  .if.lg-row-gap-16 {
    row-gap: 1rem;
  }
  .if.lg-col-gap-16 {
    column-gap: 1rem;
  }
  .if.lg-row-gap-20 {
    row-gap: 1.25rem;
  }
  .if.lg-col-gap-20 {
    column-gap: 1.25rem;
  }
  .if.lg-row-gap-24 {
    row-gap: 1.5rem;
  }
  .if.lg-col-gap-24 {
    column-gap: 1.5rem;
  }
  .if.lg-row-gap-32 {
    row-gap: 2rem;
  }
  .if.lg-col-gap-32 {
    column-gap: 2rem;
  }
  .if.lg-row-gap-40 {
    row-gap: 2.5rem;
  }
  .if.lg-col-gap-40 {
    column-gap: 2.5rem;
  }
  .if.lg-row-gap-48 {
    row-gap: 3rem;
  }
  .if.lg-col-gap-48 {
    column-gap: 3rem;
  }
  .if.lg-row-gap-64 {
    row-gap: 4rem;
  }
  .if.lg-col-gap-64 {
    column-gap: 4rem;
  }
  .if.lg-row-gap-88 {
    row-gap: 5.5rem;
  }
  .if.lg-col-gap-88 {
    column-gap: 5.5rem;
  }
  .if.lg-row-gap-120 {
    row-gap: 7.5rem;
  }
  .if.lg-col-gap-120 {
    column-gap: 7.5rem;
  }
  .if.lg-gap-2 {
    gap: 0.125rem;
  }
  .if.lg-gap-4 {
    gap: 0.25rem;
  }
  .if.lg-gap-8 {
    gap: 0.5rem;
  }
  .if.lg-gap-12 {
    gap: 0.75rem;
  }
  .if.lg-gap-16 {
    gap: 1rem;
  }
  .if.lg-gap-20 {
    gap: 1.25rem;
  }
  .if.lg-gap-24 {
    gap: 1.5rem;
  }
  .if.lg-gap-32 {
    gap: 2rem;
  }
  .if.lg-gap-40 {
    gap: 2.5rem;
  }
  .if.lg-gap-48 {
    gap: 3rem;
  }
  .if.lg-gap-64 {
    gap: 4rem;
  }
  .if.lg-gap-88 {
    gap: 5.5rem;
  }
  .if.lg-gap-120 {
    gap: 7.5rem;
  }
}
/* Targets desktop computers, laptops, touchpads */
/* Targets smart TVs, video game consoles */
/* Targets stylus-based screens */
/* Targets smartphones, touchscreens */
@media (hover: hover) and (pointer: fine) {
  .if.hover-background-yellow-500:hover {
    background-color: #E9AD25;
  }
  .if.hover-color-yellow-500:hover {
    color: #E9AD25;
  }
  .if.hover-border-color-yellow-500:hover {
    border-color: #E9AD25;
  }
  .if.hover-background-yellow-400:hover {
    background-color: #FCC74F;
  }
  .if.hover-color-yellow-400:hover {
    color: #FCC74F;
  }
  .if.hover-border-color-yellow-400:hover {
    border-color: #FCC74F;
  }
  .if.hover-background-yellow-300:hover {
    background-color: #F8D077;
  }
  .if.hover-color-yellow-300:hover {
    color: #F8D077;
  }
  .if.hover-border-color-yellow-300:hover {
    border-color: #F8D077;
  }
  .if.hover-background-yellow-200:hover {
    background-color: #F7DFA1;
  }
  .if.hover-color-yellow-200:hover {
    color: #F7DFA1;
  }
  .if.hover-border-color-yellow-200:hover {
    border-color: #F7DFA1;
  }
  .if.hover-background-yellow-100:hover {
    background-color: #F9EBC7;
  }
  .if.hover-color-yellow-100:hover {
    color: #F9EBC7;
  }
  .if.hover-border-color-yellow-100:hover {
    border-color: #F9EBC7;
  }
  .if.hover-background-brown-900:hover {
    background-color: #24150C;
  }
  .if.hover-color-brown-900:hover {
    color: #24150C;
  }
  .if.hover-border-color-brown-900:hover {
    border-color: #24150C;
  }
  .if.hover-background-brown-800:hover {
    background-color: #331E11;
  }
  .if.hover-color-brown-800:hover {
    color: #331E11;
  }
  .if.hover-border-color-brown-800:hover {
    border-color: #331E11;
  }
  .if.hover-background-brown-700:hover {
    background-color: #6E625E;
  }
  .if.hover-color-brown-700:hover {
    color: #6E625E;
  }
  .if.hover-border-color-brown-700:hover {
    border-color: #6E625E;
  }
  .if.hover-background-brown-600:hover {
    background-color: #9F948F;
  }
  .if.hover-color-brown-600:hover {
    color: #9F948F;
  }
  .if.hover-border-color-brown-600:hover {
    border-color: #9F948F;
  }
  .if.hover-background-brown-500:hover {
    background-color: #B7AEA8;
  }
  .if.hover-color-brown-500:hover {
    color: #B7AEA8;
  }
  .if.hover-border-color-brown-500:hover {
    border-color: #B7AEA8;
  }
  .if.hover-background-beige-600:hover {
    background-color: #D6CEC8;
  }
  .if.hover-color-beige-600:hover {
    color: #D6CEC8;
  }
  .if.hover-border-color-beige-600:hover {
    border-color: #D6CEC8;
  }
  .if.hover-background-beige-500:hover {
    background-color: #E8E0D9;
  }
  .if.hover-color-beige-500:hover {
    color: #E8E0D9;
  }
  .if.hover-border-color-beige-500:hover {
    border-color: #E8E0D9;
  }
  .if.hover-background-beige-400:hover {
    background-color: #EDE6E1;
  }
  .if.hover-color-beige-400:hover {
    color: #EDE6E1;
  }
  .if.hover-border-color-beige-400:hover {
    border-color: #EDE6E1;
  }
  .if.hover-background-beige-300:hover {
    background-color: #F1ECE8;
  }
  .if.hover-color-beige-300:hover {
    color: #F1ECE8;
  }
  .if.hover-border-color-beige-300:hover {
    border-color: #F1ECE8;
  }
  .if.hover-background-beige-200:hover {
    background-color: #F6F3F0;
  }
  .if.hover-color-beige-200:hover {
    color: #F6F3F0;
  }
  .if.hover-border-color-beige-200:hover {
    border-color: #F6F3F0;
  }
  .if.hover-background-beige-100:hover {
    background-color: #FAF9F7;
  }
  .if.hover-color-beige-100:hover {
    color: #FAF9F7;
  }
  .if.hover-border-color-beige-100:hover {
    border-color: #FAF9F7;
  }
  .if.hover-background-blue-500:hover {
    background-color: #1241A2;
  }
  .if.hover-color-blue-500:hover {
    color: #1241A2;
  }
  .if.hover-border-color-blue-500:hover {
    border-color: #1241A2;
  }
  .if.hover-background-blue-400:hover {
    background-color: #0054F0;
  }
  .if.hover-color-blue-400:hover {
    color: #0054F0;
  }
  .if.hover-border-color-blue-400:hover {
    border-color: #0054F0;
  }
  .if.hover-background-blue-300:hover {
    background-color: #5A8AE2;
  }
  .if.hover-color-blue-300:hover {
    color: #5A8AE2;
  }
  .if.hover-border-color-blue-300:hover {
    border-color: #5A8AE2;
  }
  .if.hover-background-blue-200:hover {
    background-color: #A8C2F0;
  }
  .if.hover-color-blue-200:hover {
    color: #A8C2F0;
  }
  .if.hover-border-color-blue-200:hover {
    border-color: #A8C2F0;
  }
  .if.hover-background-blue-100:hover {
    background-color: #E1E9F6;
  }
  .if.hover-color-blue-100:hover {
    color: #E1E9F6;
  }
  .if.hover-border-color-blue-100:hover {
    border-color: #E1E9F6;
  }
  .if.hover-background-red-500:hover {
    background-color: #812308;
  }
  .if.hover-color-red-500:hover {
    color: #812308;
  }
  .if.hover-border-color-red-500:hover {
    border-color: #812308;
  }
  .if.hover-background-red-400:hover {
    background-color: #BB320C;
  }
  .if.hover-color-red-400:hover {
    color: #BB320C;
  }
  .if.hover-border-color-red-400:hover {
    border-color: #BB320C;
  }
  .if.hover-background-red-300:hover {
    background-color: #D16547;
  }
  .if.hover-color-red-300:hover {
    color: #D16547;
  }
  .if.hover-border-color-red-300:hover {
    border-color: #D16547;
  }
  .if.hover-background-red-200:hover {
    background-color: #F1B398;
  }
  .if.hover-color-red-200:hover {
    color: #F1B398;
  }
  .if.hover-border-color-red-200:hover {
    border-color: #F1B398;
  }
  .if.hover-background-red-100:hover {
    background-color: #F9E3D4;
  }
  .if.hover-color-red-100:hover {
    color: #F9E3D4;
  }
  .if.hover-border-color-red-100:hover {
    border-color: #F9E3D4;
  }
  .if.hover-background-green-500:hover {
    background-color: #183F1F;
  }
  .if.hover-color-green-500:hover {
    color: #183F1F;
  }
  .if.hover-border-color-green-500:hover {
    border-color: #183F1F;
  }
  .if.hover-background-green-400:hover {
    background-color: #217331;
  }
  .if.hover-color-green-400:hover {
    color: #217331;
  }
  .if.hover-border-color-green-400:hover {
    border-color: #217331;
  }
  .if.hover-background-green-300:hover {
    background-color: #4B9B5A;
  }
  .if.hover-color-green-300:hover {
    color: #4B9B5A;
  }
  .if.hover-border-color-green-300:hover {
    border-color: #4B9B5A;
  }
  .if.hover-background-green-200:hover {
    background-color: #B6D5B5;
  }
  .if.hover-color-green-200:hover {
    color: #B6D5B5;
  }
  .if.hover-border-color-green-200:hover {
    border-color: #B6D5B5;
  }
  .if.hover-background-green-100:hover {
    background-color: #E0F0DB;
  }
  .if.hover-color-green-100:hover {
    color: #E0F0DB;
  }
  .if.hover-border-color-green-100:hover {
    border-color: #E0F0DB;
  }
  .if.hover-background-white:hover {
    background-color: #FFFFFF;
  }
  .if.hover-color-white:hover {
    color: #FFFFFF;
  }
  .if.hover-border-color-white:hover {
    border-color: #FFFFFF;
  }
  .if.hover-background-black:hover {
    background-color: black;
  }
  .if.hover-color-black:hover {
    color: black;
  }
  .if.hover-border-color-black:hover {
    border-color: black;
  }
  .if.hover-background-transparent:hover {
    background-color: transparent;
  }
  .if.hover-color-transparent:hover {
    color: transparent;
  }
  .if.hover-border-color-transparent:hover {
    border-color: transparent;
  }
  .if.hover-background-inherit:hover {
    background-color: inherit;
  }
  .if.hover-color-inherit:hover {
    color: inherit;
  }
  .if.hover-border-color-inherit:hover {
    border-color: inherit;
  }
}
@media (hover: hover) and (pointer: fine) {
  .if.hover-cursor-none:hover {
    cursor: none;
  }
  .if.hover-cursor-context-menu:hover {
    cursor: context-menu;
  }
  .if.hover-cursor-help:hover {
    cursor: help;
  }
  .if.hover-cursor-pointer:hover {
    cursor: pointer;
  }
  .if.hover-cursor-progress:hover {
    cursor: progress;
  }
  .if.hover-cursor-text:hover {
    cursor: text;
  }
  .if.hover-cursor-not-allowed:hover {
    cursor: not-allowed;
  }
  .if.hover-cursor-zoom-in:hover {
    cursor: zoom-in;
  }
  .if.hover-cursor-zoom-out:hover {
    cursor: zoom-out;
  }
}
@media (hover: hover) and (pointer: fine) {
  .if.hover-shadow-400:hover {
    box-shadow: 0 8px 24px 0 rgba(51, 30, 17, 0.1411764706);
  }
  .if.hover-shadow-300:hover {
    box-shadow: 0 8px 12px 0 rgba(51, 30, 17, 0.1215686275);
  }
  .if.hover-shadow-200:hover {
    box-shadow: 0 4px 8px 0 rgba(51, 30, 17, 0.1019607843);
  }
}
@media (hover: hover) and (pointer: fine) {
  .if.hover-underline:hover {
    text-decoration: underline;
  }
}